import API from '../../Service/Api'
import { DRIVER_LIST_DATA, TRIP_HISTORY_DATA, TRIP_HISTORY_EVERY_DATA,COMPANY_CARD_LIST, TRIP_LIST_DATA, COMMISSION, RATELISTDATA, COMPANY_HISTORY_LIST } from '../Contstant/Constants';
import { ToastContainer, toast } from 'react-toastify';
export const Login_data=(userinfo,navigate)=>async(dispatch)=>{
    try {
        const data=await API.post('/Adminlogin',userinfo)
        console.log(data.data.message);
        if(data.data.status==200){
          
            localStorage.setItem('accessToken', data.data.data.accessToken);
         
          window.location.href = '/add_trip';
      
          }else{
           

            toast.warning(data.data.message)
          
          }
    } catch (error) {
        console.log(error)
        toast.warning(error.message)
    }
}



export const addTrip =(data_tipe)=>async(dispatch)=>{
   try {
  let tripe_save=  await API.post('Addtripe',data_tipe);
  if(tripe_save){
    toast.success(tripe_save.data.message)
  }else{
    toast.warning(tripe_save.data.message)
  }
    
   } catch (error) {
    toast.error(error.message)
   }
}
export const TripListAction=()=>async(dispatch)=>{
  try {
    let triplist=  await API.get('TripList');
   
    if(triplist.data.status==200){
      dispatch({
        type:TRIP_LIST_DATA,
        payload:triplist.data.data
      })
    }
  } catch (error) {
    toast.error(error.message)
  }
}

export const CanceltripData=(id)=>async(dispatch)=>{
  try {
    let triplist=  await API.get(`Canceltrip?trip_id=${id}`);
   
  } catch (error) {
    toast.error(error.message)
  }
}
export const DriverlistdataList=()=>async(dispatch)=>{
  try {
    let triplist=  await API.get('Driverlistdata');
   
    if(triplist.data.status==200){
      dispatch({
        type:DRIVER_LIST_DATA,
        payload:triplist.data.data
      })
    }
  } catch (error) {
    toast.error(error.message)
  }
}


export const Adddriver_admin =(driver_detail)=>async(dispatch)=>{
  try {
 let tripe_save=  await API.post('Adddriver_admin',driver_detail);
 if(tripe_save){
   toast.success(tripe_save.data.message)
 }else{
   toast.warning(tripe_save.data.message)
 }
   
  } catch (error) {
   toast.error(error.message)
  }
}



export const Drivertriphistory=(id)=>async(dispatch)=>{
  try {
    let triplist=  await API.get(`Drivertriphistory?id=${id}`);
   
    if(triplist.data.status==200){
      dispatch({
        type:TRIP_HISTORY_DATA,
        payload:triplist.data.data
      })
    }
  } catch (error) {
    toast.error(error.message)
  }
}
export const TripHistory=()=>async(dispatch)=>{
  try {
    let triplist=  await API.get(`TripHistory`);
   
    if(triplist.data.status==200){
      dispatch({
        type:TRIP_HISTORY_EVERY_DATA,
        payload:triplist.data.data
      })
    }
  } catch (error) {
    toast.error(error.message)
  }
}
export const DeleteUser=(data)=>async(dispatch)=>{
  try {
    let triplist=  await API.get(`DeleteUser?id=${data}`);
   
   return triplist.data.message
  } catch (error) {
    toast.error(error.message)
  }
}


export const Companycardlist=()=>async(dispatch)=>{
  try {
    let triplist=  await API.get(`Companycardlist`);
   
    if(triplist.data.status==200){
      dispatch({
        type:COMPANY_CARD_LIST,
        payload:triplist.data.data
      })
    }
  } catch (error) {
    toast.error(error.message)
  }
}


export const addcompanycardSa =(driver_detail)=>async(dispatch)=>{
  try {
 let tripe_save=  await API.post('addcompanycard',driver_detail);
 if(tripe_save){
   toast.success(tripe_save.data.message)
 }else{
   toast.warning(tripe_save.data.message)
 }
   
  } catch (error) {
   toast.error(error.message)
  }
}

export const DeleteCompany=(data)=>async(dispatch)=>{
  try {
    let Company=  await API.get(`DeleteCompany?id=${data}`);
   
  if(Company.data.status==400){
    toast.error(Company.data.message)
  }
  } catch (error) {
    toast.error(error.message)
  }
}


export const SendNotification=(data)=>async(dispatch)=>{
  try {
    let notification=  await API.get(`SendNotification?id=${data}`);
   
  if(notification.data.status==400){
    toast.error(notification.data.message)
  }else{
    toast.error(notification.data.message)
  }
  } catch (error) {
    toast.error(error.message)
  }
}

export const addcomission =(driver_detail)=>async(dispatch)=>{
  try {
 let tripe_save=  await API.post('addcomission',driver_detail);
 if(tripe_save){
   toast.success(tripe_save.data.message)
 }else{
   toast.warning(tripe_save.data.message)
 }
   
  } catch (error) {
   toast.error(error.message)
  }
}

export const GetCommision=()=>async(dispatch)=>{
  try {
    let commisiondata=  await API.get(`GetCommision`);
   
    if(commisiondata.data.status==200){
      dispatch({
        type:COMMISSION,
        payload:commisiondata.data.data
      })
    }
  } catch (error) {
    toast.error(error.message)
  }
}


export const import_dealer =(companyratelist)=>async(dispatch)=>{
  try {
 let tripe_save=  await API.post('import_dealer',companyratelist);
 if(tripe_save){
   toast.success(tripe_save.data.message)
 }else{
   toast.warning(tripe_save.data.message)
 }
   
  } catch (error) {
   toast.error(error.message)
  }
}

export const CompanyRatelist=(id)=>async(dispatch)=>{
  try {
    let ratelist=  await API.get(`CompanyRatelist?id=${id}`);
   
    if(ratelist.data.status==200){
      dispatch({
        type:RATELISTDATA,
        payload:ratelist.data.data
      })
    }
  } catch (error) {
    toast.error(error.message)
  }
}


export const UpdateCompanydestination =(ratelistupdate)=>async(dispatch)=>{
  try {
 let tripe_save=  await API.post('UpdateCompanydestination',ratelistupdate);
 if(tripe_save){
  //  toast.success(tripe_save.data.message)
 }else{
   toast.warning(tripe_save.data.message)
 }
   
  } catch (error) {
   toast.error(error.message)
  }
}


export const deleteCompanydestination=(id)=>async(dispatch)=>{
  try {
    let ratelist=  await API.get(`deleteCompanydestination?id=${id}`);
   
    if(ratelist.data.status==200){
      toast.error(ratelist.data.message)
    }
  } catch (error) {
    toast.error(error.message)
  }
}

export const AdminApprove=(id,status)=>async(dispatch)=>{
  try {
    let ratelist=  await API.get(`AdminApprove?id=${id}&status=${status}`);
   
    if(ratelist.data.status==200){
    
    }
  } catch (error) {
    toast.error(error.message)
  }
}

export const Companyhistory=(id)=>async(dispatch)=>{
  try {
    let Companyhistory=  await API.get(`Companyhistory?company_rate_id=${id}`);
   
    if(Companyhistory.data.status==200){
    dispatch({
      type:COMPANY_HISTORY_LIST,
      payload:Companyhistory.data.data
    })
    }
  } catch (error) {
    toast.error(error.message)
  }
}

