/*global google*/
import React, { useEffect, useState } from "react";
import MapWithAMarker from "./MyMap";
import { useFormik } from "formik";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addTrip, Companycardlist, CompanyRatelist, DriverlistdataList } from "../../Redux/Actions/Authenticaiton";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Header from "./Header";
import { useTranslation } from 'react-i18next';
import { Input, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material";
import Button from '@mui/material/Button';
import img from "../../images/img.png"
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function Index() {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [value, setValue] = React.useState(dayjs('2022-04-17'));


  const [direction, setDirection] = useState(null);

  const [pickup, setPickUp] = useState(null);
  const [pick_up_location_name, setpick_up_location_name] = useState(null);
  const [drop_up_location_name, setdrop_up_location_name] = useState(null);

  const [company_rate_data, setcompany_rate_id] = useState("");
  const [ride_time_type_data, setride_time_type] = useState("");
  const [ride_set_date, setride_set_date] = useState("");
  const [ride_set_time, setride_set_time] = useState("");
  const [show_user_list, Setshow_user_list] = useState("");

  const [write_your_notes, Setwrite_your_notes] = useState("");
  const [company_name, Setcompany_name] = useState("");
  const [ride_type, Setride_type] = useState("Emphasis");

  const [show_user_list_company, Setshow_user_list_company] = useState("");


  const [showUser, Setuser] = useState("");



  useEffect(() => {
    dispatch(DriverlistdataList())
  }, [])
  let driver_listData = useSelector((state) => state.userSigninReducer.driver_list);

  useEffect(() => {
    dispatch(Companycardlist())
  }, []);
  const companycardlistdata = useSelector((state) => state.userSigninReducer.company_card_list)



  const [dropPoint, setDropPoint] = useState(null);
  useEffect(() => {
    if (pickup !== null && dropPoint !== null) {
      const DirectionsService = new google.maps.DirectionsService();
      DirectionsService.route(
        {
          origin: new google.maps.LatLng(pickup.lat, pickup.lng),
          destination: new google.maps.LatLng(dropPoint.lat, dropPoint.lng),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirection(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [dropPoint, pickup]);




  const ride_time_type = (e) => {

    setride_time_type(e.target.value)
    console.log(e.target.value);

  }

  const date_pick = (e) => {
    setride_set_date(e.target.value)
  }
  const time_pick = (e) => {
    setride_set_time(e.target.value)
  }

  const company_rate_id = (e) => {
    setcompany_rate_id(e.target.value)
  }

  const select_user = (e) => {
    Setuser(e.target.value)
  }

  const formik = useFormik({
    initialValues: {
      username: "",
      phone_number: "",
      ride_type: "",
      pick_up_lat: "",
      pick_up_long: "",
      drop_up_lat: "",
      drop_long: "",
      pick_up_location_name: "",
      drop_up_location_name: "",
      ride_time_type: ""

    },
    validationSchema: Yup.object({
      username: Yup.string().required('User name is required'),
      phone_number: Yup.string().required('Phone number is required'),

    }),
    onSubmit: (values) => {
      values.date = ride_set_date;
      values.time = ride_set_time;
      values.pick_up_lat = pickup.lat
      values.ride_time_type = ride_time_type_data;
      values.pick_up_long = pickup.lng
      values.drop_up_lat = dropPoint.lat
      values.drop_long = dropPoint.lng
      values.pick_up_location_name = pick_up_location_name;
      values.drop_up_location_name = drop_up_location_name;
      values.write_your_notes = write_your_notes;
      values.ride_type = ride_type;
      values.company_name = company_name;
      values.company_rate_id = company_rate_data

      values.user_data = showUser;
      dispatch(addTrip(values)).then(function () {
        window.location.href = '/trip-list';
      })


    }
  })
  console.log(show_user_list,);
  const ride_typechange = (e) => {
    Setride_type(e.target.value)
    if (e.target.value == "Corporate") {
      Setshow_user_list_company(show_user_list_company == "" ? "show" : "")
    } else {
      Setshow_user_list_company(show_user_list_company == "show" ? "" : "")
    }


  }
  const select_company_name = (e) => {

    Setcompany_name(e.target.value)
    dispatch(CompanyRatelist(e.target.value)).then(function () {

    })
  }
  const ratelistdatalist = useSelector((state) => state.userSigninReducer.ratelistdata);
  console.log(ratelistdatalist, "ratelistdatalist")

  return (
 <div >
  <br/><br/>
    <Paper variant="outlined" style={{width:"1000px",marginLeft:"162px",backgroundImage:{img}}}  onSubmit={formik.handleSubmit} >
  <br/>
      <h2 style={{ color: "#FFA500" ,fontSize:"43px",textAlign:"center"}}><u>{t('Home.1')}</u> :</h2>
      <br/>
      <div style={{margin:"13px"}}>
      <br />
        <div>
          <div>
            <InputLabel  id="demo-simple-select-label">Ride Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ride_type}
              onChange={ride_typechange}
              style={{width:"193px"}}
            >
              <MenuItem value="Emphasis">{t('Home.Individual/Corporate')}</MenuItem>
              <MenuItem value="Individual">{t('Home.Individual')}</MenuItem>
              <MenuItem value="Corporate">{t('Home.Corporate')}</MenuItem>
            </Select>
            {formik.errors.ride_type && (
              <p style={{ color: "red" }}>{formik.errors.ride_type} </p>
            )}
          </div>
          <br/>

          <div className={`col-sm-12 mb-2 p-0 mt-2  ${show_user_list_company == "show" ? '' : "dispay_none"}`}>
            <lable>{t('Home.Please Select Company')}</lable>
            <select
              className="form-selectna form-control"
              aria-label="Default select example"
              onChange={select_company_name}
              name="company_id"
            >
              <option value="">{t('Home.4')}</option>
              {
                companycardlistdata.map(function (data, i) {



                  return (<option value={data._id}>  {data.company_name}</option>)

                })
              }
            </select>
          </div>
          <div className={`col-sm-12 mb-2 p-0 mt-2  ${show_user_list_company == "show" ? '' : "dispay_none"}`}>
            <lable>{t('Home.Please Select Company')}</lable>
            <select
              className="form-selectna form-control"
              aria-label="Default select example"
              onChange={company_rate_id}
              name="destination"
            >
              <option>{t('Home.4')}</option>
              {
                ratelistdatalist.map(function (data_obj, i) {

                  return (<option value={data_obj._id}>  {data_obj.company_destination}</option>)

                })
              }
            </select>
          </div>
          <div className="row">
        <div className="col-sm-6 mb-3 mb-sm-0">
          <TextField
             fullWidth
             id="username"
             placeholder={t('Home.2')}
             label={t('Home.2')}
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
             value={formik.values.username}
          />
          {formik.errors.username && (
            <p style={{ color: 'red' }}>{formik.errors.username}</p>
          )}
        </div>
        <div className="col-sm-6 mb-3 mb-sm-0">
          <TextField
            fullWidth
            id="phone number"
            name="phone_number"
            placeholder={t('Home.3')}
            label={t('Home.3')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone_number}
          />
          {formik.errors.phone_number && (
            <p style={{ color: 'red' }}>{formik.errors.phone_number}</p>
          )}
        </div>
      </div>
        </div>
        <br/>
        <div className="row form-group loctions-select">
          <div className="col-6">
            <GooglePlacesAutocomplete
              selectProps={{
                // isClearable: true,

                onChange: (o) => {
                  setdrop_up_location_name(o.label);

                  if (o) {
                    geocodeByAddress(o.value.description)
                      .then((results) => getLatLng(results[0]))
                      .then(({ lat, lng }) => {
                        setPickUp({
                          lat,
                          lng,
                        });
                      });
                  }
                },
              }}
            />
          </div>

        </div>
        <div className="col-sm-12 mb-2 p-0 mt-2">
          <Select
            labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={ride_time_type}
              value={ride_time_type_data}
              style={{width:"193px"}}
          >
            <MenuItem value="1">{t('Home.Ride Now/Schedule')}</MenuItem>
            <MenuItem value="1">  {t('Home.Ride Now')}</MenuItem>
            <MenuItem value="2"> {t('Home.Schedule')}</MenuItem>

          </Select>
        </div>
        <div className={`row ${ride_time_type_data == 2 ? '' : "dispay_none"}`} >
          <div className="col-6">
            <input type="date" onChange={date_pick} className="form-control form-control-user" id="pickuplocation" placeholder="Pickup time" />
          </div>

          <div className="col-6">

            <input type="time" onChange={time_pick} className="form-control form-control-user" id="droplocation" placeholder="Pickup Date" />
          </div>
        </div>
        <br/>
        <div className=" mt-2">

          <div>

          <Button variant="contained" style={{color:"white",backgroundColor:"#DAA520"}}  onClick={() => {
              Setshow_user_list(show_user_list == "" ? "show" : "")
            }}>
          {t('Home.Select Driver')}{" "}          </Button>

          </div>
        </div>
        <br/>
        <div className={`col-sm-12 mb-2 p-0 mt-2 ${show_user_list == "show" ? '' : "dispay_none"}`}>
        <InputLabel  id="demo-simple-select-label">Select Driver</InputLabel>

          <Select
            name="ride_time_type"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={ride_type}
            onChange={select_user}
            style={{width:"193px"}}
          >
            <MenuItem  value="select Driver">{t('Home.Select Driver')}</MenuItem>
            {
              driver_listData.map(function (data, i) {
                if (data.user_active == 1 && data.profile_detail == 1) {
                  return (
                    <MenuItem key={data._id} value={data._id}>
                      {data.first_name + ' ' + data.last_name}
                    </MenuItem>
                  );
                }
              })}
              </Select>
        </div>
      


        <div className={`col-sm-12 mb-2 p-0 mt-2 `}>
          <textarea className="form-control form-control-user  mt-3" onChange={(e) => {
            Setwrite_your_notes(e.target.value)

          }} placeholder={t('Home.write your note')} row="10" cols="20">

          </textarea>

        </div>
        <div className=" mt-5">
          <div>
            <Button type="submit" variant="contained" style={{color:"white",backgroundColor:"#DAA520"}}>
          {" "}
              {t('Home.7')}{" "}        </Button>

          </div>
        </div>
      </div>
      
    </Paper>
    </div>

  );
}


