// import React, { useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next';
// import Master from '../Includes/Master'
// import Modal from 'react-bootstrap/Modal';
// import { useFormik } from "formik";
// import * as Yup from 'yup';
// import { useDispatch, useSelector } from 'react-redux';
// import { addcompanycardSa, Companycardlist, DeleteCompany } from '../../Redux/Actions/Authenticaiton';
// import { NavLink } from 'react-router-dom';
// export default function CompanyCard() {
//   const [show, setShow] = useState(false);
  
//   const [deletecompanymodal, setdeletecompanymodal] = useState(false);

//   const [id, setid] = useState("");
//   const [idcompany, setidcompany] = useState("");
//   const { t } = useTranslation();
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const handleClosecompany = () => setdeletecompanymodal(false);
//   const handleShowcompany = (data) => {setdeletecompanymodal(true);
//     setidcompany(data)};
//   const dispatch=useDispatch();
//   useEffect(()=>{
//     dispatch(Companycardlist())
//   },[]);
//   const companycardlistdata=useSelector((state)=>state.userSigninReducer.company_card_list);
//   console.log(companycardlistdata,"lllllllllllllllll")
//   const formik= useFormik({
//     initialValues:{
//         company_name:"",
//         contact_name:"",
//         line_of_business:"",
//         type_of_user:"",
//       phone:"",
//       mobile_phone:"",
//       contact_phone:"",
//       approve_by:"",
     
     
  
//     },
//     validationSchema : Yup.object({
//         company_name:Yup.string().required('Company Name is required') ,
//         contact_name:Yup.string().required('Contact Name is required') ,
//         line_of_business:Yup.string().required('Business line is required') ,
//         type_of_user:Yup.string().required('Type is required') ,
//         mobile_phone: Yup.string().required('Mobile Phone is required'),
//       phone: Yup.string().required('Phone is required'),
//       approve_by:Yup.string().required('Approve by is required'),
//       contact_phone:Yup.string().required('Contact phone is required'),
     
      
//     }),
//     onSubmit:(values)=>{
//         values.id=id;
//    dispatch(addcompanycardSa(values)).then(function(){
//     dispatch(Companycardlist())
//      setShow(false)
//    })
   
  

//     }
//   });
  
//   const edit_company=(data)=>{
//   formik.setFieldValue('company_name',data.company_name)
//   formik.setFieldValue('contact_name',data.contact_name)
//   formik.setFieldValue('line_of_business',data.line_of_business)
//   formik.setFieldValue('type_of_user',data.type_of_user)
//   formik.setFieldValue('mobile_phone',data.mobile_phone)
//   formik.setFieldValue('phone',data.phone)
//   formik.setFieldValue('approve_by',data.approve_by)
//   formik.setFieldValue('contact_phone',data.contact_phone)
 
//   setid(data._id)
//     setShow(true)
//   }
  
//   const delete_company=()=>{
//     dispatch(DeleteCompany(idcompany)).then(function(){
//         dispatch(Companycardlist())
//         setdeletecompanymodal(false)
//     })
   
//   }


//   return (
//    <div>

//     <br/><br/><br/><br/><br/><br/>
//                                 <div className="card shadow mb-4">
//                             <div className="card-header py-3 button-driver">
//                                 <div >
//                                     <h6 className="m-0 font-weight-bold text-primary">{t('Home.Company card')}</h6>
//                                 </div>
//                                 <div><a href="#" className="main-btn-cl" onClick={handleShow} >{t('Home.Create Company card')}</a></div>
//                             </div> 
//                             <div className="card-body">
//                             <div className="table-responsive">
//                                             <table className="table table-bordered triptable" id="dataTable" width="1750px" cellspacing="0">
//                                             <thead>
//                                             <tr>
//                                                 <th>{t('Home.Company Name')}</th>
//                                                 <th>{t('Home.Contact Name')}</th>
//                                                 <th>{t('Home.Line of Business')}</th>
//                                                 <th>{t('Home.Type of company')}</th>
//                                                 <th>{t('Home.phone')}</th>
//                                                 <th>{t('Home.Mobile Phone')}</th>
//                                                 <th>{t('Home.contact phone')}</th>
//                                                 <th>{t('Home.Travel approval by')}</th>
//                                                 <th>{t('Home.company_report_view')}</th>
//                                                 <th>{t('Home.Rate view')}</th>
                                               
//                                                 <th colspan="2"> {t('Home.Action')}</th>
//                                             </tr>
//                                         </thead>
                                      
//                                         <tbody>
//                                             {
//                                              companycardlistdata.map(function(companycardlistobj,i){
//                                                 return(<tr>
//                                             <td>{companycardlistobj.company_name}</td>
//                                             <td>{companycardlistobj.contact_name}</td>
//                                             <td>{companycardlistobj.line_of_business}</td>
//                                             <td>{companycardlistobj.type_of_user}</td>
//                                             <td>{companycardlistobj.phone}</td>
//                                             <td>{companycardlistobj.mobile_phone}</td>
//                                             <td>{companycardlistobj.contact_phone}</td>
//                                             <td>{companycardlistobj.approve_by}</td>
                                            
//                                             <td><NavLink to={'/company-report/'+companycardlistobj._id}><button  className="main-btn-cl" >{t('Home.company_report_view')}</button></NavLink></td>
//                                             <td><NavLink to={'/companyratelist/'+companycardlistobj._id}><button  className="main-btn-cl" >{t('Home.View')}</button></NavLink></td>
//                                             <td ><button  className="main-btn-cl" onClick={()=>{
//                                                edit_company(companycardlistobj) 
//                                             }}>{t('Home.Edit')}</button>
                                            
                                          
//                                             </td>
//                                             <td >
                                            
//                                             <button  className="btn  btn-danger" onClick={()=>{
//                                               handleShowcompany(companycardlistobj._id)
//                                             }}>{t('Home.Delete')}</button>
                                            
//                                             </td>
//                                                     </tr>)
//                                              })   
//                                             }
                                            
//                                             </tbody>
        
//                                         </table>
//                                         </div>
//                                     </div>
//                                     </div>


//                 <Modal show={show} onHide={handleClose}>
//                     <Modal.Header closeButton>
//                     <Modal.Title>{t('Home.Company card')}</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
     
             
//                     <form className="user"  onSubmit={formik.handleSubmit}>
//                         <div className="form-group row">
//                             <div className="col-sm-6 mb-3 mb-sm-0">
//                                 <input type="text" className="form-control form-control-user" id="firstname"
//                                     placeholder={t('Home.Company Name')} name="company_name" 
//                                      onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.company_name} 
                                    
//                                     />
//                            {formik.errors.company_name && <p style={{color:"red"}}>{formik.errors.company_name} </p>}

//                             </div>
//                             <div className="col-sm-6">
//                                 <input type="text" className="form-control form-control-user" id="lastname"
//                                    placeholder={t('Home.Contact Name')}
//                                     name="contact_name" 
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.contact_name} 
                                    
//                                     />
//                   {formik.errors.contact_name && <p style={{color:"red"}}>{formik.errors.contact_name} </p>}

//                             </div>
//                         </div>
//                         <div className="form-group row">
//                             <div className="col-sm-6 mb-3 mb-sm-0">
//                                 <input type="text" className="form-control form-control-user"   id="email"
//                                     placeholder={t('Home.Line of Business')}
//                                     name='line_of_business'
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.line_of_business} 
//                                     />
//     {formik.errors.line_of_business && <p style={{color:"red"}}>{formik.errors.line_of_business} </p>}

//                             </div>
//                             <div className="col-sm-6">
                               
//                         <select   className="form-selectna form-control"
//                   aria-label="Default select example"  name="type_of_user"   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.type_of_user} >
//                             <option >
                                
//                                 {t('Home.Dealer')}
//                             </option>
//                             <option>
                                
//                                 {t('Home.Ltd')}
//                             </option>

//                         </select>
//                         {formik.errors.type_of_user && <p style={{color:"red"}}>{formik.errors.type_of_user} </p>}
                                   
//                             </div>
//                         </div>
//                         <div className="col-sm-12 mb-2 p-0">
//                             <input 
//                                 type="text"
//                                 className="form-control form-control-user" 
//                                 id="phoneNumber"
//                                 placeholder={t('Home.phone')}
//                                 name='phone'
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.phone} 
                                
                                
//                                 />
//           {formik.errors.phone && <p style={{color:"red"}}>{formik.errors.phone} </p>}

//                         </div>
//                         <div className="col-sm-12 mb-3 p-0">
//                             <input 
//                                 type="text"
//                                 className="form-control form-control-user" 
//                                 id="vehiclebrand"
//                                 placeholder={t('Home.Mobile Phone')}
//                                 name='mobile_phone'
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.mobile_phone} 
                                
                                
//                                 />
//                        {formik.errors.mobile_phone && <p style={{color:"red"}}>{formik.errors.mobile_phone} </p>}

//                         </div>
//                         <div className="form-group row">
//                             <div className="col-sm-6 mb-3 mb-sm-0">
//                                 <input 
//                                     type="text"
//                                     className="form-control form-control-user" 
//                                     id="model"
//                                     placeholder={t('Home.contact phone')}
//                                     name='contact_phone'
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.contact_phone} 
                                    
                                    
                                    
//                                      />

//                  {formik.errors.contact_phone && <p style={{color:"red"}}>{formik.errors.contact_phone} </p>}
//                             </div>
//                             <div className="col-sm-6 ">
//                                 <input type="text" className="form-control form-control-user"   id="Year"
//                                     placeholder={t('Home.Travel approval by')}
                                    
//                                     name='approve_by'
//                                     onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.approve_by} 
                                    
//                                     />
//                                     {formik.errors.approve_by && <p style={{color:"red"}}>{formik.errors.approve_by} </p>}
//                             </div>
//                         </div>
                       
                      

//                         <button  className="btt btn-primary">{t('Home.Create Company card')} </button>
//                         </form>
               
               
//         </Modal.Body>
     
//               </Modal>


//               <Modal show={deletecompanymodal} onHide={handleClosecompany}>
//         <Modal.Header closeButton>
//           <Modal.Title> {t('Home.Delete Comapny Card')}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>{t('Home.Are want to sure for delete')}?</Modal.Body>
//         <Modal.Footer>
//           <button className="btn btn-secondary" onClick={handleClosecompany}>
            
//             {t('Home.Close')}
//           </button>
//           <button className="btn btn-danger" onClick={()=>{
//             delete_company()
//           }}>
            
//             {t('Home.Delete')}
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   )
// }


import React, { useEffect, useState } from 'react'
import Master from '../Includes/Master'
import Paper from '@mui/material/Paper';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Adddriver_admin, Companycardlist, DriverlistdataList } from '../../Redux/Actions/Authenticaiton';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';





function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export default function CompanyCard() {
    const [deletecompanymodal, setdeletecompanymodal] = useState(false);

    // const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const [id, setid] = useState("");
    const [idcompany, setidcompany] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClosecompany = () => setdeletecompanymodal(false);
    const handleShowcompany = (data) => {setdeletecompanymodal(true);
      setidcompany(data)};

    const dispatch=useDispatch();
    useEffect(()=>{
      dispatch(Companycardlist())
    },[]);
    const companycardlistdata=useSelector((state)=>state.userSigninReducer.company_card_list);
  
    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            city: "",
            phone_number: "",
            vehicle_brand: "",
            model: "",
            build_year: "",
            plate_number: "",
            password_data: ""


        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('First Name is required'),
            last_name: Yup.string().required('Last Name is required'),
            // email:Yup.string().email().required('Email is required') ,
            city: Yup.string().required('City is required'),
            vehicle_brand: Yup.string().required('Brand is required'),
            phone_number: Yup.string().required('Phone is required'),
            build_year: Yup.string().required('Year is required'),
            model: Yup.string().required('Model is required'),
            plate_number: Yup.string().required('Plate Number is required'),
            password_data: Yup.string().required('Password is required')

        }),
        onSubmit: (values) => {
            values.id = id;

            dispatch(Adddriver_admin(values)).then(function () {
                dispatch(DriverlistdataList())
                setShow(false)
            })

        }
    })
    const edit_driver_detail = (driver_detail) => {

        setid(driver_detail?._id)
        formik.setFieldValue('first_name', driver_detail?.first_name)
        formik.setFieldValue('last_name', driver_detail?.last_name)
        formik.setFieldValue('email', driver_detail?.email)
        formik.setFieldValue('city', driver_detail?.city)
        formik.setFieldValue('phone_number', driver_detail?.phone_number)
        formik.setFieldValue('vehicle_brand', driver_detail?.vehicle?.vehicle_brand)
        formik.setFieldValue('build_year', driver_detail?.vehicle?.build_year)
        formik.setFieldValue('model', driver_detail?.vehicle?.model)
        formik.setFieldValue('password_data', driver_detail?.vehicle?.password_data)

        formik.setFieldValue('plate_number', driver_detail?.vehicle?.plate_number)
        setShow(true)
    }


    const { t } = useTranslation();


    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: t('Home.Company Name'),
            width: '50%',
        },
        {
            id: 'calories',
            numeric: true,
            disablePadding: false,
            label: t('Home.Contact Name'),
            width: '20%',
        },
        {
            id: 'fat',
            numeric: true,
            disablePadding: false,
            label: t('Home.Line of Business'),
            width: '20%',
        },
        {
            id: 'carbs',
            numeric: true,
            disablePadding: false,
            label: t('Home.Type of company'),
            width: '20%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.phone'),
            width: '10%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.Mobile Phone'),
            width: '10%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.contact phone'),
            width: '5%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.Travel approval by'),
            width: '5%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.company_report_view'),
            width: '5%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.Rate view'),
            width: '5%',
        },
        {
          id: 'protein',
          numeric: true,
          disablePadding: false,
          label: t('Home.Action'),
          width: '5%',
      },
    ];

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (

            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ width: headCell.width }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(companycardlistdata, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    const edit_company=(data)=>{
        formik.setFieldValue('company_name',data.company_name)
        formik.setFieldValue('contact_name',data.contact_name)
        formik.setFieldValue('line_of_business',data.line_of_business)
        formik.setFieldValue('type_of_user',data.type_of_user)
        formik.setFieldValue('mobile_phone',data.mobile_phone)
        formik.setFieldValue('phone',data.phone)
        formik.setFieldValue('approve_by',data.approve_by)
        formik.setFieldValue('contact_phone',data.contact_phone)
       
        setid(data._id)
          setShow(true)
        }
    return (

        <div>
            <div className="card shadow mb-4">
                <div className="card-header py-3 button-driver">
                    <div >
                        <h2 style={{ color: "#DAA520" }}>{t('Home.Company card')}</h2>
                    </div>
                    <div><Button href="#" style={{ backgroundColor: "#DAA520", color: "white" }} onClick={handleShow} >{t('Home.Create Company card')}</Button></div>
                </div>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.company_name}
                                            </TableCell>
                                            <TableCell align="right">{row.contact_name}</TableCell>
                                            <TableCell align="right">{row.line_of_business}</TableCell>
                                            <TableCell align="right">{row.type_of_user}</TableCell>
                                            <TableCell align="right">{row.phone}</TableCell>
                                            <TableCell align="right">{row.mobile_phone}</TableCell>
                                            <TableCell align="right">{row.contact_phone}</TableCell>
                                            <TableCell align="right">{row.approve_by}</TableCell>
                                            <TableCell align="right"><NavLink to={'/companyratelist/'+row._id}><Button style={{ color: "white", backgroundColor: "#DAA520" }}>{t('Home.View')}</Button></NavLink></TableCell>
                                            <TableCell align="right"><NavLink to={'/companyratelist/'+row._id}><Button style={{ color: "white", backgroundColor: "#DAA520" }}>{t('Home.View')}</Button></NavLink></TableCell>

                                            <TableCell align="right"><Button style={{ color: "white", backgroundColor: "#DAA520" }}   onClick={()=>{
                                               edit_company(row) 
                                            }}>{t('Home.Edit')}</Button></TableCell>
                                            <TableCell align="right"><Button style={{ color: "white", backgroundColor: "red" }} onClick={()=>{
                                              handleShowcompany(row._id)
                                            }}>{t('Home.Delete')}</Button></TableCell>

                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                        <TableCell colSpan={8} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </Paper>

            </div>
          
            <Modal show={show} onHide={handleClose}>
              <br/><br/>
                    <Modal.Header closeButton>
                    <Modal.Title>{t('Home.Company card')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
     
             
                    <form className="user"  onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" className="form-control form-control-user" id="firstname"
                                    placeholder={t('Home.Company Name')} name="company_name" 
                                     onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.company_name} 
                                    
                                    />
                           {formik.errors.company_name && <p style={{color:"red"}}>{formik.errors.company_name} </p>}

                            </div>
                            <div className="col-sm-6">
                                <input type="text" className="form-control form-control-user" id="lastname"
                                   placeholder={t('Home.Contact Name')}
                                    name="contact_name" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.contact_name} 
                                    
                                    />
                  {formik.errors.contact_name && <p style={{color:"red"}}>{formik.errors.contact_name} </p>}

                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" className="form-control form-control-user"   id="email"
                                    placeholder={t('Home.Line of Business')}
                                    name='line_of_business'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.line_of_business} 
                                    />
    {formik.errors.line_of_business && <p style={{color:"red"}}>{formik.errors.line_of_business} </p>}

                            </div>
                            <div className="col-sm-6">
                               
                        <select   className="form-selectna form-control"
                  aria-label="Default select example"  name="type_of_user"   onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.type_of_user} >
                            <option >
                                
                                {t('Home.Dealer')}
                            </option>
                            <option>
                                
                                {t('Home.Ltd')}
                            </option>

                        </select>
                        {formik.errors.type_of_user && <p style={{color:"red"}}>{formik.errors.type_of_user} </p>}
                                   
                            </div>
                        </div>
                        <div className="col-sm-12 mb-2 p-0">
                            <input 
                                type="text"
                                className="form-control form-control-user" 
                                id="phoneNumber"
                                placeholder={t('Home.phone')}
                                name='phone'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone} 
                                
                                
                                />
          {formik.errors.phone && <p style={{color:"red"}}>{formik.errors.phone} </p>}

                        </div>
                        <div className="col-sm-12 mb-3 p-0">
                            <input 
                                type="text"
                                className="form-control form-control-user" 
                                id="vehiclebrand"
                                placeholder={t('Home.Mobile Phone')}
                                name='mobile_phone'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobile_phone} 
                                
                                
                                />
                       {formik.errors.mobile_phone && <p style={{color:"red"}}>{formik.errors.mobile_phone} </p>}

                        </div>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <input 
                                    type="text"
                                    className="form-control form-control-user" 
                                    id="model"
                                    placeholder={t('Home.contact phone')}
                                    name='contact_phone'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.contact_phone} 
                                    
                                    
                                    
                                     />

                 {formik.errors.contact_phone && <p style={{color:"red"}}>{formik.errors.contact_phone} </p>}
                            </div>
                            <div className="col-sm-6 ">
                                <input type="text" className="form-control form-control-user"   id="Year"
                                    placeholder={t('Home.Travel approval by')}
                                    
                                    name='approve_by'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.approve_by} 
                                    
                                    />
                                    {formik.errors.approve_by && <p style={{color:"red"}}>{formik.errors.approve_by} </p>}
                            </div>
                        </div>
                       
                      

                        <button  className="btt btn-primary">{t('Home.Create Company card')} </button>
                        </form>
               
               
        </Modal.Body>
     
              </Modal>


              <Modal >
        <Modal.Header closeButton>
          <Modal.Title> {t('Home.Delete Comapny Card')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('Home.Are want to sure for delete')}?</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" >
            
            {t('Home.Close')}
          </button>
          {/* <button className="btn btn-danger" onClick={()=>{
            delete_company()
          }}> */}
            
            {/* {t('Home.Delete')}
          </button> */}
        </Modal.Footer>
      </Modal>

        </div>
    )
}








