import React from "react";
import Index from "./Index.jsx";

export default function Addtrip() {
  return (
    <Index>
      <form>
        <input type="text" />
      </form>
    </Index>
  );
}
