import React, { useState } from 'react'
import Master from '../Includes/Master'
import Modal from 'react-bootstrap/Modal';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Adddriver_admin, DeleteUser, DriverlistdataList } from '../../Redux/Actions/Authenticaiton';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { t } from 'i18next';
export default function Drivermanagement1() {
   const [show, setShow] = useState(false);
   const [id, setid] = useState("");
   const [iddriver, setdriver] = useState("");
    const handleClose = () => setShow(false);
    const [deletedriver, setdeletedriver] = useState(false);
    const handleClosedriver = () => setdeletedriver(false);
    const handleShowdriver = (data) =>{ setdeletedriver(true);
      setdriver(data)};
    const formik= useFormik({
      initialValues:{
          first_name:"",
          last_name:"",
          // email:"",
          city:"",
        phone_number:"",
        vehicle_brand:"",
        model:"",
        build_year:"",
        plate_number:"",
        password_data:""
       
    
      },
      validationSchema : Yup.object({
          first_name:Yup.string().required('First Name is required') ,
          last_name:Yup.string().required('Last Name is required') ,
          // email:Yup.string().email().required('Email is required') ,
          city:Yup.string().required('City is required') ,
          vehicle_brand: Yup.string().required('Brand is required'),
        phone_number: Yup.string().required('Phone is required'),
        build_year:Yup.string().required('Year is required'),
        model:Yup.string().required('Model is required'),
        plate_number:Yup.string().required('Plate Number is required'),
        password_data:Yup.string().required('Password is required'),
        
      }),
      onSubmit:(values)=>{
        values.id=id;
       
    dispatch(Adddriver_admin(values)).then(function(){
      dispatch(DriverlistdataList())
      setShow(false)
    })
 
      }
    })

    const handleShow = (driver_detail) => {
      setid(driver_detail?._id)
        formik.setFieldValue('first_name',driver_detail?.first_name)
        formik.setFieldValue('last_name',driver_detail?.last_name)
        formik.setFieldValue('email',driver_detail?.email)
        formik.setFieldValue('city',driver_detail?.city)
        formik.setFieldValue('phone_number',driver_detail?.phone_number)
        formik.setFieldValue('vehicle_brand',driver_detail?.vehicle?.vehicle_brand)
        formik.setFieldValue('build_year',driver_detail?.vehicle?.build_year)
        formik.setFieldValue('model',driver_detail?.vehicle?.model)
        formik.setFieldValue('plate_number',driver_detail?.vehicle?.plate_number)
        formik.setFieldValue('password_data',driver_detail?.password_data)
      setShow(true);
    };


    const dispatch=useDispatch();
    useEffect(()=>{
      dispatch(DriverlistdataList())
    },[])
    let driver_listData=useSelector((state)=>state.userSigninReducer.driver_list);

  return (
    <Master>
                <div className="row mb-4">
                  {driver_listData.map(function(dealer_detail){
                    if(dealer_detail.profile_detail==1){

                    
                     return( <div className="col-md-4">
                      
                     <div className="profiles text-center">
                     <span style={{'margin-left':" 231px"}} className="btn"><svg  style={{height:"17px"}} onClick={ ()=>{

                          handleShowdriver(dealer_detail._id)
                     
                    
                     }}
 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
<path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg></span>
                   

                        <div className="profileimg ">
                        <img src={require("../Assets/img/profile.png")} />
                        </div>
                        <div className="contentsetup">
                           <h5 className="m-0 font-weight-bold  mt-3  text-primary">{dealer_detail.first_name+" "+dealer_detail.last_name}</h5>
                             <div className="priflebutton mt-4">
                              <a href="#" className="btt btn-primary " onClick={()=>handleShow(dealer_detail)}> {t('Home.View Driver')}</a>   
                                 <NavLink to= {'/driver-reports/'+dealer_detail?._id} className="btt btn-success">  {t('Home.Driver Reports')}</NavLink>
                               </div>
                        </div>
                     </div>
                  </div>)
                  } })}
                    
                    
                  </div>
                  
             
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>{t('Home.13')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
     
             
                    <form className="user" onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" className="form-control form-control-user" id="firstname"
                                    placeholder="First name" name="first_name" 
                                     onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.first_name} 
                                    
                                    />
                                    {formik.errors.first_name && <p style={{color:"red"}}>{formik.errors.first_name} </p>}                     
                            </div>
                            <div className="col-sm-6">
                                <input type="tel" className="form-control form-control-user" id="lastname"
                                    placeholder="Last Name"
                                    name="last_name" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.last_name} 
                                    
                                    />
                                    {formik.errors.last_name && <p style={{color:"red"}}>{formik.errors.last_name} </p>}                     
                            </div>
                        </div>
                        <div className="form-group row">
                            {/* <div className="col-sm-6 mb-3 mb-sm-0">
                                <input type="email" className="form-control form-control-user"   id="email"
                                    placeholder={t('Home.28')}
                                    name='email'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email} 
                                    />
                                    {formik.errors.email && <p style={{color:"red"}}>{formik.errors.email} </p>}    
                            </div> */}
                            <div className="col-sm-6">
                                <input 
                                    type="text"
                                    className="form-control form-control-user" 
                                    id="city"
                                    placeholder={t('Home.29')}
                                    
                                    name='city'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city} 
                                    />
                                     {formik.errors.city && <p style={{color:"red"}}>{formik.errors.city} </p>}  
                            </div>
                  
                        <div className="col-sm-12 ">
                            <input 
                                type="tel"
                                className="form-control form-control-user" 
                                id="phoneNumber"
                                placeholder={t('Home.17')}
                                name='phone_number'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone_number} 
                                
                                
                                />
                                {formik.errors.phone_number && <p style={{color:"red"}}>{formik.errors.phone_number} </p>} 
                        </div>
                        </div>
                        <h1 className="h5 text-gray-900 mb-4">{t('Home.Vehicle Details')}:</h1>
                        <div className="col-sm-12 mb-3 p-0">
                            <input 
                                type="tel"
                                className="form-control form-control-user" 
                                id="vehiclebrand"
                                placeholder={t('Home.30')}
                                name='vehicle_brand'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.vehicle_brand} 
                                
                                
                                />
                                {formik.errors.vehicle_brand && <p style={{color:"red"}}>{formik.errors.vehicle_brand} </p>}
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <input 
                                    type="text"
                                    className="form-control form-control-user" 
                                    id="model"
                                    placeholder={t('Home.31')}
                                    name='model'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.model} 
                                    
                                    
                                    
                                     />
                                     {formik.errors.model && <p style={{color:"red"}}>{formik.errors.model} </p>}
                            </div>
                            <div className="col-sm-6 ">
                                <input type="text" className="form-control form-control-user"   id="Year"
                                    placeholder={t('Home.32')}
                                    
                                    name='build_year'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.build_year} 
                                    
                                    />
                                    {formik.errors.build_year && <p style={{color:"red"}}>{formik.errors.build_year} </p>}
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3 mt-2 p-0">
                            <input 
                                type="text"
                                className="form-control form-control-user" 
                                id="Plate Number"
                                placeholder={t('Home.33')}
                                
                                name='plate_number'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.plate_number} 
                                
                                />
                                 {formik.errors.plate_number && <p style={{color:"red"}}>{formik.errors.plate_number} </p>}
                        </div>


                        <div className="col-sm-12 mb-3 mt-2 p-0">
                            <input 
                                type="text"
                                className="form-control form-control-user" 
                              
                                placeholder={t('Home.password_data')}
                                
                                name='password_data'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password_data} 
                                
                                />
                                 {formik.errors.password_data && <p style={{color:"red"}}>{formik.errors.password_data} </p>}
                        </div>


                        <button  className="btt btn-primary"> {t('Home.Update Driver')} </button>
                        </form>
               
               
        </Modal.Body>
     
                   </Modal>

                   <Modal show={deletedriver} onHide={handleClosedriver}>
                    <Modal.Header closeButton>
                    <Modal.Title> {t('Home.Delete Driver')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t('Home.Are want to sure for delete')}?</Modal.Body>
                    <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClosedriver}>

                    {t('Home.Close')}
                    </button>
                    <button className="btn btn-danger" onClick={()=>{
                  dispatch(DeleteUser(iddriver)).then(function(){
                    setdeletedriver(false)
                        dispatch(DriverlistdataList())
                      })
                    }}>

                    {t('Home.Delete')}
                    </button>
                    </Modal.Footer>
                    </Modal>

    </Master>
  )
}
