import React, { useEffect } from 'react'
import Master from '../Includes/Master'

/*global google*/


import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  DirectionsRenderer,
} from "react-google-maps";
import { withProps, compose, lifecycle } from "recompose";
import MapWithAMarker from '../Includes/MyMap';
import { DriverlistdataList } from '../../Redux/Actions/Authenticaiton';
import { useDispatch, useSelector } from 'react-redux';
import MapwithMarkermap from './MapwithMarkermap';







  const MapWithADirectionsRenderer = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBmf3LyxAmvQZNBzw5ZyblOD0_8tDhQucY&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` ,width:"100%" }} />,
      containerElement: <div style={{ height: `500px`,width:"100%" }} />,
      mapElement: <div style={{ height: `100%`,width:"100%" }} />,
      customData: "customDat",
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
      componentDidMount() {
        const DirectionsService = new google.maps.DirectionsService();
        console.log("_dd google.maps", google.maps);
      
      },
    })
  )((props) => (
    <GoogleMap
      defaultZoom={4}
    //   defaultCenter={new google.maps.LatLng(-37.8136, 144.9631)}

      defaultCenter={  new google.maps.LatLng(32.0684, 34.8248)}
     
    >
     
  
    </GoogleMap>
  ));
  
  


export default function ViewMap() {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(DriverlistdataList())
  },[])
  let driver_listData=useSelector((state)=>state.userSigninReducer.driver_list);

  return (
  <div> 
    <br/><br/>
<MapwithMarkermap
          
          user_list={driver_listData}

          loadingElement={<div style={{ height: `100%`,width:`100%` }} />}
          containerElement={<div style={{ height: `500px`,width:`100%` }} />}
          mapElement={<div style={{ height: `100%`,width:`100%` }} />}
        />
   </div>
  )
}
