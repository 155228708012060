// import React from 'react'
// import { useEffect } from 'react'
// import { useTranslation } from 'react-i18next';
// import { useDispatch, useSelector } from 'react-redux'
// import { TripHistory } from '../../Redux/Actions/Authenticaiton';
// import Master from '../Includes/Master'

// export default function Triphistory() {
//     const { t } = useTranslation();
//     const dispatch=useDispatch();
//     useEffect(()=>{
//         dispatch(TripHistory())
//     },[])

//     let trip_list_data=useSelector((state)=>state.userSigninReducer.trip_list_data)
// const datacheck=(data)=>{
//     var data_print=[];
// for(var i=0;i<parseInt(data);i++){
//     data_print.push(<span className="fa fa-star checked"></span>)
    
// }
// return data_print
// }
//   return (
// <div>
//     <br/><br/>
// <div className="card-header py-3">
//     <h6 className="m-0 font-weight-bold text-primary">{t('Home.12')}</h6>
// </div>
// <div className="card-body">
//     <div className="table-responsive">
//         <table className="table table-bordered triptable" id="dataTable" width="100%" cellspacing="0">
//             <thead>
//                 <tr>
//                 <th>{t('Home.16')}</th>
//                 <th>{t('Home.17')}</th>
//                 <th>{t('Home.18')}</th>
//                 <th>{t('Home.19')}</th>
//                 <th>{t('Home.20')} </th>
//                 <th>{t('Home.20')} </th>
              
//                     <th> {t('Home.Ride Fare')}</th>
                   
//                     <th> {t('Home.21')}</th>
//                     <th> {t('Home.22')}</th>
                   
//                     <th>{t('Home.Rating by driver')}</th>
//                 </tr>
//             </thead>
            
//             <tbody>
//                 {
//                     trip_list_data.map(function(obj,i){
//                     return(
//                         <tr key={i}>
//                         <td>{obj?.trip_id?.username}</td>
//                         <td>{obj?.trip_id?.phone_number}</td>
//                         <td>{obj?.trip_id?.pick_up_location_name}</td>
//                         <td>{obj?.trip_id?.drop_up_location_name}</td>
//                         <td>{obj?.trip_id?.ride_type}</td>
//                         <td>{obj.trip_id?.ride_time_type==1?"instant":"scheduled"}</td>
//                         <td>{obj.price} R/s</td>
//                         <td>{obj?.trip_id?.date}</td>
//                         <td>{obj?.trip_id?.time}</td>
//                         <td className="starrating">
//                           {
//                             datacheck(obj.trip_id?.raiting)
//                           }
                            
                            
                           
//                         </td>
//                     </tr>
//                     )
//                     })
//                 }
              
                
//             </tbody>
//         </table>
//     </div>
// </div>

// </div>



//   )
// }


import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CanceltripData, TripListAction } from '../../Redux/Actions/Authenticaiton';
import Master from '../Includes/Master'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Button } from 'react-bootstrap';
import { Rating } from '@mui/material';

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export default function TripHistory() {
  const { t } = useTranslation();
  const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(TripListAction())
    },[])
    let trip_list_data=useSelector((state)=>state.userSigninReducer.trip_list_data)
    console.log(trip_list_data,"Trippppppppppppppppppppppppppppppppppppppppppppppp")
    const datacheck=(data)=>{
      var data_print=[];
  for(var i=0;i<parseInt(data);i++){
      data_print.push(<span className="fa fa-star checked"></span>)
      
  }
  return data_print
  }

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('Home.16'),
      width: '50%',
    },
    {
      id: 'calories',
      numeric: true,
      disablePadding: false,
      label: t('Home.17'),
      width: '20%',
    },
    {
      id: 'fat',
      numeric: true,
      disablePadding: false,
      label: t('Home.18'),
      width: '20%',
    },
    {
      id: 'carbs',
      numeric: true,
      disablePadding: false,
      label: t('Home.19'),
      width: '20%',
    },
    {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label: t('Home.20'),
      width: '10%',
    },
    {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label: t('Home.Ride Fare'),
      width: '10%',
    },
    {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label:t('Home.21'),
      width: '5%',
    },
    {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label: t('Home.22'),
      width: '5%',
    },
    {
    id: 'protein',
      numeric: true,
      disablePadding: false,
      label: t('Home.Rating by driver'),
      width: '5%',
    }
  ];
  
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ width: headCell.width }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(trip_list_data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
    <div>
    
 <div className="card shadow mb-4">
       <div className="card-header py-3 button-driver">
                    <div >
                        <h2 style={{ color: "#DAA520" }}>{t('Home.9')}</h2>
                    </div>
                </div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      
        <TableContainer>
          <Table   stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row?.trip_id?.username}
                    </TableCell>
                    <TableCell align="right">{row.trip_id?.phone_number}</TableCell>
                    <TableCell align="right">{row.trip_id?.pick_up_location_name}</TableCell>
                    <TableCell align="right">{row.trip_id?.drop_up_location_name}</TableCell>
                    <TableCell align="right">{row.trip_id?.ride_time_type==1?"instant":"scheduled"}</TableCell>
                    <TableCell align="right">{row.price}</TableCell>
                    <TableCell align="right">{row.trip_id?.date}</TableCell>

                    <TableCell align="right">{row.trip_id?.time}</TableCell>
                    <TableCell align="right">{row.trip_id?.raiting && (
    <Rating name="read-only" value={row.trip_id.raiting} readOnly />
  )}

            
</TableCell>

                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
 
      </Paper>
      </div>
    </div>
   
   
  );
}



