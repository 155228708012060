import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AdminApprove, DriverlistdataList } from '../../Redux/Actions/Authenticaiton';
import Master from '../Includes/Master'

export default function Userlist() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);



    const dispatch=useDispatch();
    useEffect(()=>{
      dispatch(DriverlistdataList())
    },[])
    let driver_listData=useSelector((state)=>state.userSigninReducer.driver_list)
  return (
    <Master>
            <div className="card shadow mb-4">
            <div className="card-body">
            <div className="table-responsive">
            <table className="table table-bordered triptable" id="dataTable" width="1750px" cellspacing="0">
            <thead>
                <tr>
                    <th>{t('Home.27')}</th>
                    {/* <th>{t('Home.28')}</th> */}
                    <th>{t('Home.29')}</th>
                    <th>{t('Home.17')}</th>
                    <th>{t('Home.30')}</th>
                    <th>{t('Home.31')}</th>
                    <th>{t('Home.32')}</th>
                    <th>{t('Home.33')}</th>
                    <th colSpan={2}>{t('Home.Status')}</th>
                </tr>
            </thead>
            <tbody>
                
                
                {driver_listData.map((driver_list_obj,i)=>{
                    if(driver_list_obj.admin_approved==0)
                    {

                    
                    return(
                        <tr key={i}>
                        <td>{driver_list_obj?.first_name +" "+driver_list_obj?.last_name}</td>
                        {/* <td>{driver_list_obj.email}</td> */}
                        <td>{driver_list_obj.city}</td>
                        <td>{driver_list_obj.phone_number}</td>
                        <td>{driver_list_obj?.vehicle?.vehicle_brand}</td>
                        <td>{driver_list_obj?.vehicle?.model}</td>
                        <td>{driver_list_obj?.vehicle?.build_year}</td>
                        <td >{driver_list_obj?.vehicle?.plate_number}</td>
                        
                        <td >
                            <button className="btt btn-success" onClick={()=>{
                                dispatch(AdminApprove(driver_list_obj?._id,1)).then(function(){
                                    dispatch(DriverlistdataList())
                                })
                            }}>{t('Home.Accept')}</button>
                            <button className="btt btn-danger mt-2" onClick={()=>{
                                dispatch(AdminApprove(driver_list_obj?._id,2)).then(function(){
                                    dispatch(DriverlistdataList())
                                })
                            }}s>{t('Home.Reject')}</button>
                        
                        </td>
                    </tr>
                    )
                } })}
        
                
                
                
                
            </tbody>
            </table>
            </div>
            </div>
            </div>
    </Master>
  )
}
