import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Master from './Component/Includes/Master';
import Drivermanagement from './Component/DriverManagement/Drivermanagement';
import Triphistory from './Component/Triphistory/Triphistory';
import Drivermanagement1 from './Component/Driver/Drivermanagement1';
import Driver_reports from './Component/Driver/Driver_reports';
import Addtrip from './Component/Includes/Addtrip';
import MapDriverShow from './Component/MapDriverSHow/MapDriverShow';
import CompanyCard from './Component/CompanyCard/CompanyCard';
import RateList from './Component/RateList/RateList';
import Login from './Component/Login/Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Admincommision from './Component/AdminCommision/Admincommision';
import DriverCommision from './Component/Driver/DriverCommision';
import CompanyRateList from './Component/RateList/CompanyRateList';
import Userlist from './Component/Userlist/Userlist';
import Protedcted from './Component/Protected/Protedcted';
import Company_reports from './Component/CompanyCard/Company_reports';
import ViewMap from './Component/ViewMap/ViewMap';
import Triplistmaster from './Component/Trip/Triplistmaster';
import Addtripmaster from './Component/Includes/Addtripmaster';
import Scheduled_master from './Component/Scheduled_list/Scheduled_master';
import DriverManagementmaster from './Component/DriverManagement/DriverManagementmaster';
import TripHistorymaster from './Component/Triphistory/TripHistorymaster';
import Company_carmaster from './Component/CompanyCard/Company_carmaster';
import Ratelistmaster from './Component/RateList/Ratelistmaster';
import Viewmapmaster from './Component/ViewMap/Viewmapmaster';
import Adminmaster from './Component/AdminCommision/Adminmaster';
import ComapnyRateListmaster from './Component/RateList/CompanyRateListmaster';

function App() {
 

const authentication=localStorage.getItem('accessToken')

  return (
    <Router>
      <Routes>
      <Route path='/' element={<Login />} />
        <Route path='/add_trip' element={<Protedcted ><Addtripmaster/></Protedcted>} />
        <Route path='/master' element={<Master />} />
        <Route path='/trip-list' element={<Protedcted ><Triplistmaster/></Protedcted>} />
        <Route path='/scheduledtrip' element={<Protedcted ><Scheduled_master/></Protedcted>} />
        <Route path='/add-driver' element={<Protedcted ><DriverManagementmaster /></Protedcted>} />
        <Route path='/trip-history' element={<Protedcted ><TripHistorymaster/></Protedcted>} />
        <Route path='/driver-management' element={<Protedcted ><Drivermanagement1 /></Protedcted>} />
        <Route path='/driver-reports/:id' element={<Driver_reports />} />
        <Route path='/mapshow' element={<Viewmapmaster />} />
        <Route path='/companycard' element={<Company_carmaster/>} />
        <Route path='/ratelist' element={<Ratelistmaster />} />
        <Route path='/admincommision' element={<Adminmaster />} />
        <Route path='/drivercommision/:id' element={<DriverCommision />} />
        <Route path='/companyratelist/:id' element={<ComapnyRateListmaster />} />
        <Route path='/company-report/:id' element={<Company_reports />} />
        <Route path='/userlist' element={<Userlist />} />
        <Route path='/mapview' element={<Viewmapmaster />} />
        {!authentication?(
        <>
         <Route path="/"  name="Master"  element={<Login />}    />
       
        
        
        </>
        ):( <Route path="*" element={<Navigate to={"/add_trip"}/>}/>)}

      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
