import React, { useEffect } from "react";
import { useState } from "react";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  DirectionsRenderer,
  Marker,
  InfoWindow
} from "react-google-maps";








const MapwithMarkermap = withGoogleMap((props) => {
 
  return (
    <GoogleMap defaultZoom={4} defaultCenter={{ lat: 32.0684, lng: 34.8248 }}>
     
      {
      props.user_list.map(function(data,i){

        if(data.user_active ==1 && data.profile_detail ==1){
  
       
        return(
          <>
           <Marker 
        key={i}
         
       

        position={
         
        
         { 
          lat:  data.location.coordinates[1]
          ,
          lng: data.location.coordinates[0],
          
        }} >
      
   <InfoWindow
   
     options={{ maxWidth: 100 }}
   >
     <>
     <p>{data.first_name +" "+data.last_name}{i}</p>
     <p>{data.vehicle?.vehicle_brand}</p>
     <p>{data.vehicle?.plate_number}</p>
     <p>{data.vehicle?.model}</p>
     </>
   
   </InfoWindow>

         
         </Marker>
          </>
       )
      }})
    
    }
   
    </GoogleMap>
  );
});
export default MapwithMarkermap;