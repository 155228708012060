import axios from "axios";
const BASEURL=process.env.REACT_APP_API_BASE_URL;
  // const BASEURL='http://localhost:8000/api';
console.log(BASEURL,"hhhhhhhhhhhhhh")
const API=axios.create({
    baseURL: BASEURL,
    
    headers: {
      authorization: localStorage.getItem('accessToken'),
      },
  });

  API.interceptors.response.use((response)=>{
    if(response.data.status==401){
      setTimeout(() => {
        localStorage.clear();
     window.location.href = '/';
 }, 1000);
    }else{
      return response
    }
   
  },(error)=>{
    if(error.response.status==401){
        setTimeout(() => {
            localStorage.clear();
         window.location.href = '/';
     }, 1000);
    }

    if(error.response.status==404){
        
    }
    console.log(error.response);
  })
  export default API;