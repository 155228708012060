export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LIST_DATA = 'USER_LIST_DATA';
export const VENDER_LIST_DATA = 'VENDER_LIST_DATA';
export const GET_CATEGOR_LIST='GET_CATEGOR_LIST';
export const GET_COMMISSION='GET_COMMISSION';
export const GET_MEMBERSHIP_LIST='GET_MEMBERSHIP_LIST';
export const GET_DASHBOARD_DATA='GET_DASHBOARD_DATA';
export const GET_EVENT_DATA='GET_EVENT_DATA';
export const GET_EVENT_DETAIL_DATA='GET_EVENT_DETAIL_DATA';
export const START_LOADING='START_LOADING';
export const STOP_LOADING='STOP_LOADING';
export const PAYMENT_HISTORY='PAYMENT_HISTORY';
export const GET_CANCEL_REQUEST_DATA='GET_CANCEL_REQUEST_DATA';
export const TRIP_LIST_DATA='TRIP_LIST_DATA'

export const DRIVER_LIST_DATA='DRIVER_LIST_DATA'

export const TRIP_HISTORY_DATA='TRIP_HISTORY_DATA'
export const TRIP_HISTORY_EVERY_DATA='TRIP_HISTORY_EVERY_DATA';
export const COMPANY_CARD_LIST='COMPANY_CARD_LIST';
export const COMMISSION='COMMISSION';
export const RATELISTDATA='RATELISTDATA';
export const COMPANY_HISTORY_LIST="COMPANY_HISTORY_LIST";









