import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CanceltripData, SendNotification, TripListAction } from '../../Redux/Actions/Authenticaiton';

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Scheduled_list() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(TripListAction());
  }, []);
  const triplist = useSelector((state) => state.userSigninReducer.triplist_data);

  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: t('Home.16'), width: '50%' },
    { id: 'calories', numeric: true, disablePadding: false, label: t('Home.17'), width: '20%' },
    { id: 'fat', numeric: true, disablePadding: false, label: t('Home.18'), width: '20%' },
    { id: 'carbs', numeric: true, disablePadding: false, label: t('Home.19'), width: '20%' },
    { id: 'protein', numeric: true, disablePadding: false, label: t('Home.20'), width: '10%' },
    { id: 'protein', numeric: true, disablePadding: false, label: t('Home.21'), width: '10%' },
    { id: 'protein', numeric: true, disablePadding: false, label: t('Home.22'), width: '5%' },
    { id: 'protein', numeric: true, disablePadding: false, label: t('Home.23'), width: '5%' },
    { id: 'protein', numeric: true, disablePadding: false, label: t('Home.24'), width: '5%' },
    { id: 'protein', numeric: true, disablePadding: false, label: t('Home.25'), width: '5%' },
    { id: 'protein', numeric: true, disablePadding: false, label: t('Home.26'), width: '5%' },
  ];


  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ width: headCell.width }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
   
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

  };

  const handleSelectAllClick = (event) => {
  if (event.target.checked) {
  const newSelected = triplist.map((n) => n.name);
  setSelected(newSelected);
  } else {
  setSelected([]);
  }
  };
  
  const handleClick = (event, name) => {
  const selectedIndex = selected.indexOf(name);
  let newSelected = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }
  
  setSelected(newSelected);

};

const handleChangePage = (event, newPage) => {
setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
setRowsPerPage(parseInt(event.target.value, 10));
setPage(0);
};

const handleChangeDense = (event) => {
setDense(event.target.checked);
};

const isSelected = (name) => selected.indexOf(name) !== -1;

const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - triplist.length) : 0;

const visibleRows = React.useMemo(
() =>
stableSort(triplist, getComparator(order, orderBy)).slice(
page * rowsPerPage,
page * rowsPerPage + rowsPerPage
),
[triplist, order, orderBy, page, rowsPerPage]
);

return (
  <div >
<div className="card shadow mb-4">
<div className="card-header py-3 button-driver">
<div>
<h2 style={{ color: "#DAA520" }}>{t('Home.10')}</h2>
</div>
</div>
<Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={triplist.length}
        />
        <TableBody>
          {visibleRows.map((row, index) => {
            const isItemSelected = isSelected(row.name);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.name)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.name}
                selected={isItemSelected}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell component="th" id={labelId} scope="row" padding="none">
                  {row.username}
                </TableCell>
                <TableCell align="right">{row.phone_number}</TableCell>
                <TableCell align="right">{row.pick_up_location_name}</TableCell>
                <TableCell align="right">{row.drop_up_location_name}</TableCell>
                <TableCell align="right">{row.ride_type}</TableCell>
                <TableCell align="right">{row.date}</TableCell>
                <TableCell align="right">{row.time}</TableCell>
                <TableCell align="right">
                  <Button style={{ color: "white", backgroundColor: "green" }}>Active</Button>
                </TableCell>
                <TableCell align="right">
                  <Button style={{ width: "60px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                      <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z" />
                    </svg>
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <Button onClick={() => dispatch(SendNotification(row._id))}>
                    {t('Home.Send Notification')}
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <Button
                    style={{ color: "white", backgroundColor: "red" }}
                    onClick={() => dispatch(CanceltripData(row._id), dispatch(TripListAction()))}
                  >
                    Cancel
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
              <TableCell colSpan={8} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={triplist.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>
</div>
  </div>

);
}
