import { START_LOADING, STOP_LOADING } from "../Contstant/Constants";

const initialstate={
    loading:""
}

const loadingreducer=(state = initialstate, action)=>{
    switch(action.type){
        case START_LOADING:
            return {
                ...state, 
                loading: action.payload};
        case STOP_LOADING:
            return {
                ...state,
                 loading: action.payload};
        default:
            return state;
    }
}
export default loadingreducer;