
import { COMMISSION, COMPANY_CARD_LIST, COMPANY_HISTORY_LIST, DRIVER_LIST_DATA, RATELISTDATA, TRIP_HISTORY_DATA, TRIP_HISTORY_EVERY_DATA, TRIP_LIST_DATA, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS } from "../Contstant/Constants";
const initialstate={
    triplist_data:[],
    driver_list:[],
    trip_historry:[],
    trip_list_data:[],
    company_card_list:[],
    commision:[],
    ratelistdata:[],
    company_history_list:[]
}

const userSigninReducer = (state = initialstate, action) => {
    console.log(action.type);
    switch(action.type) {
        case USER_LOGIN_REQUEST:
            return ({ loading: true});
            
        case USER_LOGIN_SUCCESS:

       return ({ loading: false, userInfo: action.payload});

        case USER_LOGIN_FAIL:
        return ({ loading: false, error: action.payload });

        case TRIP_LIST_DATA:
        return({
            ...state,
            triplist_data:action.payload
        })

        case DRIVER_LIST_DATA:

        return({
            ...state,
            driver_list:action.payload
        })
            case TRIP_HISTORY_DATA:
                return({
                    ...state,
                    trip_historry:action.payload
                })
                case TRIP_HISTORY_EVERY_DATA:
                    return({
                        ...state,
                        trip_list_data:action.payload
                    })
                    case COMPANY_CARD_LIST:
                        return({
                            ...state,
                            company_card_list:action.payload
                        })
                        case COMMISSION:
                            return({
                                ...state,
                                commision:action.payload

                            })
                            case RATELISTDATA:
                                return({
                                    ...state,
                                    ratelistdata:action.payload
                                })

            case COMPANY_HISTORY_LIST:
                return({
                    ...state,
                    company_history_list:action.payload
                })                
        default:
            return state;
    }
};
export default userSigninReducer;