import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./Redux/Store";
import { Provider } from "react-redux";

import './Component/Assets/vendor/fontawesome-free/css/all.min.css'
import './Component/Assets/css/taxi-admin-2.min.css'
import './Component/Assets/vendor/datatables/dataTables.bootstrap4.min.css'


import './i18n';
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
      <Suspense fallback={(<div>Loading</div>)}>
    <App />
    </Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
