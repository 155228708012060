import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Login_data } from '../../Redux/Actions/Authenticaiton';
import { t } from 'i18next';
import img1 from "../../images/img.png"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';



const defaultTheme = createTheme();
export default function SignInSide() {
  const dispatch=useDispatch()
  const formik = useFormik({
      initialValues: {
        email: '',
        password: '',
      },
       validationSchema : Yup.object({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
       
      }),
      
      onSubmit:(values)=>{

     dispatch(Login_data(values))
       
      } 
      
    });
  return (
    // <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ backgroundColor:"#DAA520"}}>
        {/* <CssBaseline /> */}
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${img1})`,
            backgroundRepeat: 'no-repeat',
            // backgroundColor: (t) =>
            //   t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            // backgroundSize: 'cover',
            height:'823px',
            width:"22px",
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              // alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#DAA520' ,marginLeft:"269px"}}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ marginLeft:"257px"}}>
              Sign in
            </Typography>
            <br/>
            <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
              <TextField
              type="email" className="form-control"
              id="exampleInputEmail" 
              aria-describedby="emailHelp"
              name="email"
              label="הזן כתובת אימייל"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email} 
              />
              <br/><br/><br/>
              {formik.errors.email && <p style={{color:"red"}}>{formik.errors.email} </p>}

              <TextField
                type="password"
                 className="form-control"
                  id="exampleInputPassword"
                label="סיסמה"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <br/><br/><br/>
            {formik.errors.password && <p style={{color:"red"}}>{formik.errors.password} </p>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    // </ThemeProvider>
  );
}


// import React from 'react'



// export default function first(){
//   return(
//     <div>
//       <h1>jhvgcfxtdsxdfcghbj</h1>
//     </div>
//   )

// }