// import { useFormik } from 'formik';
// import { t } from 'i18next';
// import React, { useState } from 'react'
// import { useEffect } from 'react';
// import { Modal } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { CompanyRatelist, deleteCompanydestination, UpdateCompanydestination } from '../../Redux/Actions/Authenticaiton';
// import Master from '../Includes/Master'
// import * as Yup from 'yup';


// export default function CompanyRateList() {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const [iddata,setid]=useState("");

//     const dispatch=useDispatch();
//     const {id }=useParams();
//     useEffect(()=>{
//         dispatch(CompanyRatelist(id))
//       },id);
//       const ratelistdatalist=useSelector((state)=>state.userSigninReducer.ratelistdata);
//       console.log(ratelistdatalist)

//       const formik= useFormik({
//         initialValues:{
//             company_destination:"",
//             rate1:"",
//             driver_price1:"",
//             profitforus1:"",
//             rate2:"",
//             driver_price2:"",
//             profitforus2:"",
         
         
      
//         },
//         validationSchema : Yup.object({
//             company_destination:Yup.string().required(`${t('Home.The destination of the trip')}`+'is required') ,
//             rate1:Yup.string().required(`${t('Home.Rate 1 for business')}`+'is required') ,
//             driver_price1:Yup.string().required(`${t('Home.Rate 1 for driver')}`+'is required') ,
//             profitforus1:Yup.string().required(`${t('Home.Rate 1 Profit for us')}`+'is required') ,
//             rate2: Yup.string().required(`${t('Home.Rate 2 for business')}`+'is required'),
//             driver_price2: Yup.string().required(`${t('Home.Rate 2 for driver')}`+'is required'),
//             profitforus2:Yup.string().required(`${t('Home.Rate 2  Profit for us')}`+' is required'),
        
          
//         }),
//         onSubmit:(values)=>{
//           values.id=iddata;
         
//       dispatch(UpdateCompanydestination(values)).then(function(){
//         dispatch(CompanyRatelist(id))
//         setShow(false)
//       })
   
//         }
//       })
  
//       const handleShow = (driver_detail) => {
//         setid(driver_detail?._id)
//           formik.setFieldValue('company_destination',driver_detail?.company_destination)
//           formik.setFieldValue('rate1',driver_detail?.rate1)
//           formik.setFieldValue('driver_price1',driver_detail?.driver_price1)
//           formik.setFieldValue('profitforus1',driver_detail?.profitforus1)
//           formik.setFieldValue('rate2',driver_detail?.rate2)
//           formik.setFieldValue('driver_price2',driver_detail?.driver_price2)
//           formik.setFieldValue('profitforus2',driver_detail?.profitforus2)
          
//         setShow(true);
//       };
//   return (
//     <div>
//         <div className="card shadow mb-4">
//                             <div className="card-header py-3">
//                                 <h6 className="m-0 font-weight-bold text-primary">{t('Home.Rate list')}</h6>
//                             </div>
//                             <div className="card-body">
//                                 <div className="table-responsive">
//                                     <table className="table table-bordered triptable" id="dataTable" width="1750px" cellspacing="0">
//                                         <thead>
//                                             <tr>
//                                                 <th>{t('Home.The destination of the trip')}</th>
//                                                 <th>{t('Home.Rate 1 for business')}</th>
//                                                 <th>{t('Home.Rate 1 for driver')}</th>
//                                                 <th>{t('Home.Rate 1  Profit for us')}</th>
//                                                 <th>{t('Home.Rate 2 for business')} </th>
//                                                 <th>{t('Home.Rate 2 for driver')} </th>
//                                                 <th>{t('Home.Rate 2  Profit for us')} </th>
//                                                 <th colSpan="2">{t('Home.Edit')} </th>
                                              
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             {
//                                                 ratelistdatalist.map((triplist_obj,i)=>{
                                                  

                                                  
//                                                    return ( 
//                                                          <tr key={i}>


//                                                     <td>{triplist_obj.company_destination}</td>
//                                                         <td>{triplist_obj.rate1}</td>
//                                                         <td>{triplist_obj.driver_price1}</td>
//                                                         <td>{triplist_obj.profitforus1}</td>
//                                                         <td>{triplist_obj.rate2}</td>
//                                                         <td>{triplist_obj.driver_price2}</td>
//                                                         <td>{triplist_obj.profitforus2}</td>
//                                                         <td ><button  className="main-btn-cl" onClick={()=>{
//                                                             handleShow(triplist_obj)
//                                                         }}>{t('Home.Edit')}</button>
                                            
                                          

                                            
//                                             </td>
//                                             <td ><button  className="main-btn-cl" onClick={()=>{
//                                                            dispatch(deleteCompanydestination(triplist_obj._id)).then(function(){
//                                                             dispatch(CompanyRatelist(id))
//                                                            })
//                                                         }}>{t('Home.Delete')}</button>
                                            
                                          

                                            
//                                             </td>
//                                                                                                </tr>);
//                                                   })
//                                             }
                                            
                                          
                                          
//                                         </tbody>
                                       
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>

//                 <Modal show={show} onHide={handleClose}>
//                     <Modal.Header closeButton>
//                     <Modal.Title>{t('Home.Add travel price')}</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
     
             
//                    <form className="user" onSubmit={formik.handleSubmit}>
                      
//                         <div className="col-sm-12 mb-3 p-0">
//                             <input 
//                                 type="text"
//                                 className="form-control form-control-user" 
//                                 id="vehiclebrand"
//                                 placeholder={t('Home.The destination of the trip')}
//                                 onChange={formik.handleChange}
//                                     onBlur={formik.handleBlur}
//                                     value={formik.values.company_destination} 
//                                     name="company_destination"


                                    
//                                     />
//                            {formik.errors.company_destination && <p style={{color:"red"}}>{formik.errors.company_destination} </p>}

//                         </div>
//                         <div className="col-sm-12 mb-3 p-0">
//                             <input 
//                                 type="text"
//                                 className="form-control form-control-user" 
//                                 id="vehiclebrand"
//                                 placeholder={t('Home.Rate 1 for business')}
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.rate1} 
//                                 name="rate1"


                                
//                                 />
//                        {formik.errors.rate1 && <p style={{color:"red"}}>{formik.errors.rate1} </p>}

//                         </div>
//                         <div className="col-sm-12 mb-3 p-0">
//                             <input 
//                                 type="text"
//                                 className="form-control form-control-user" 
//                                 id="vehiclebrand"
//                                 placeholder={t('Home.Rate 1 for driver')}
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.driver_price1} 
//                                 name="driver_price1"


                                
//                                 />
//                        {formik.errors.driver_price1 && <p style={{color:"red"}}>{formik.errors.driver_price1} </p>}

//                         </div>
//                         <div className="col-sm-12 mb-3 p-0">
//                             <input 
//                                 type="text"
//                                 className="form-control form-control-user" 
//                                 id="vehiclebrand"
//                                 placeholder={t('Home.Rate 1  Profit for us')}
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.profitforus1} 
//                                 name="profitforus1"


                                
//                                 />
//                        {formik.errors.profitforus1 && <p style={{color:"red"}}>{formik.errors.profitforus1} </p>}

//                         </div>
                       
//                         <div className="col-sm-12 mb-3 p-0">
//                             <input 
//                                 type="text"
//                                 className="form-control form-control-user" 
//                                 id="vehiclebrand"
//                                 placeholder={t('Home.Rate 2 for business')}
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.rate2} 
//                                 name="rate2"


                                
//                                 />
//                        {formik.errors.rate2 && <p style={{color:"red"}}>{formik.errors.rate2} </p>}

//                         </div>
//                         <div className="col-sm-12 mb-3 p-0">
//                             <input 
//                                 type="text"
//                                 className="form-control form-control-user" 
//                                 id="vehiclebrand"
//                                 placeholder={t('Home.Rate 2 for driver')}
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.driver_price2} 
//                                 name="driver_price2"


                                
//                                 />
//                        {formik.errors.driver_price2 && <p style={{color:"red"}}>{formik.errors.driver_price2} </p>}

//                         </div>
//                         <div className="col-sm-12 mb-3 p-0">
//                             <input 
//                                 type="text"
//                                 className="form-control form-control-user" 
//                                 id="vehiclebrand"
//                                 placeholder={t('Home.Rate 2  Profit for us')}
//                                 onChange={formik.handleChange}
//                                 onBlur={formik.handleBlur}
//                                 value={formik.values.profitforus2} 
//                                 name="profitforus2"


                                
//                                 />
//                        {formik.errors.profitforus2 && <p style={{color:"red"}}>{formik.errors.profitforus2} </p>}

//                         </div>
                       
//                         <button  type='submit' className="btt btn-primary">{t('Home.Add travel price')} </button>
                     
//               </form>
               
//         </Modal.Body>
     
//               </Modal>

//     </div>

 
//   )
// }


import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CanceltripData, TripListAction } from '../../Redux/Actions/Authenticaiton';
import Master from '../Includes/Master'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Button } from 'react-bootstrap';

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export default function Triplist() {
  const { t } = useTranslation();
  const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(TripListAction())
    },[])
    let triplist=useSelector((state)=>state.userSigninReducer.triplist_data)


  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('Home.16'),
      width: '20%',
    },
    {
      id: 'calories',
      numeric: true,
      disablePadding: false,
      label: t('Home.17'),
      width: '20%',
    },
    {
      id: 'fat',
      numeric: true,
      disablePadding: false,
      label: t('Home.18'),
      width: '20%',
    },
    {
      id: 'carbs',
      numeric: true,
      disablePadding: false,
      label: t('Home.19'),
      width: '20%',
    },
    {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label: t('Home.20'),
      width: '10%',
    },
    {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label: t('Home.23'),
      width: '10%',
    },
    {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label:t('Home.24'),
      width: '5%',
    },
    {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label: t('Home.26'),
      width: '5%',
    },
  ];
  
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ width: headCell.width }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(triplist, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
  <div style={{marginLeft:"82px"}}>
        <br/><br/> <br/><br/>
        <div className="card shadow mb-4">
       <div className="card-header py-3 button-driver">
                    <div >
                        <h2 style={{ color: "#DAA520" }}>{t('Home.9')}</h2>
                    </div>
                </div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      
        <TableContainer>
          <Table   stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell  align="right">
                      {row.username}
                    </TableCell>
                    <TableCell align="right">{row.phone_number}</TableCell>
                    <TableCell align="right">{row.pick_up_location_name}</TableCell>
                    <TableCell align="right">{row.drop_up_location_name}</TableCell>
                    <TableCell align="right">{ t(`Home.${row.ride_type}`)}</TableCell>
                    <TableCell align="right"><Button style={{color:"white",backgroundColor:"green"}}>{row.ride_time_type==1?t(`Home.instant`): t(`Home.scheduled`)}</Button></TableCell>
                    <TableCell align="right"><Button style={{width:"60px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"/>
                                            </svg></Button></TableCell>
                    <TableCell align="right"><Button style={{color:"white",backgroundColor:"red"}}  onClick={()=>dispatch(CanceltripData(row._id), dispatch(TripListAction()))}>{t(`Home.Cancel`)}</Button></TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
 
      </Paper>
      </div>
   
  </div>
    
  );
}

