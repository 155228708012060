import { t } from 'i18next'
import React, { useEffect } from 'react'
import Master from '../Includes/Master'
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Companycardlist, import_dealer } from '../../Redux/Actions/Authenticaiton';
export default function RateList() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [filedata,setfile]=useState("")
    const [id,setid]=useState("")
    const filesave=(e)=>{
        console.log(e.target.files[0],"lllllllllllllllllll")
       
        setfile(e.target.files[0])
    }
    const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(Companycardlist())
  },[]);
  const companycardlistdata=useSelector((state)=>state.userSigninReducer.company_card_list);
    const submitfile=()=>{
  
let appendfile=new FormData();
appendfile.append('excelfile',filedata)
appendfile.append('id',id)
dispatch(import_dealer(appendfile)).then(function(){
    setShow(false)

})
    }
  return (
  <div>
    <br/><br/>
    <div className="card shadow mb-4">
    <div className="card-header py-3 button-driver">
        <div >
            <h2 style={{ color: "#DAA520" }}>{t('Home.Travel price list')}</h2>

        </div>
        <div><Button href="#" style={{ backgroundColor: "#DAA520", color: "white" }} onClick={handleShow} >{t('Home.Add travel price')}</Button></div>
        </div> 
          <div className="card-body">
        <div className="table-responsive">
      
          </div>
        </div> 
        </div>
                <Modal show={show} onHide={handleClose}>
                  <br/><br/>
                    <Modal.Header closeButton>
                    <Modal.Title>{t('Home.Add travel price')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>        
                        <div className="col-sm-12 mb-3 p-0">
                            <input 
                                type="file"
                                className="form-control form-control-user" 
                                id="vehiclebrand"
                                placeholder={t('Home.price')}
                                name='vehicle_brand'
                                onChange={filesave}
                               
                                
                                />
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12 mb-3 mb-sm-0">
                            <select
                  className="form-selectna form-control"
                  aria-label="Default select example"
                  onChange={(e)=>{
                    setid(e.target.value)
                  }}
                  name="ride_time_type"
                >
                  <option> {t('Home.Corporate')}</option>
                  {
                    companycardlistdata.map(function(data,i){
                     

                   
                      return(  <option value={data._id}>  {data.company_name}</option>)
                    
                    })
                  }
                
          
               
                </select>
                            </div>
                          
                       
                        </div>
                       
                       
                        <button  onClick={()=>{
                            submitfile();
                        }} className="btt btn-primary">{t('Home.Add travel price')} </button>
                     
              
               
        </Modal.Body>
     
                </Modal>
    </div>
  )
}
