import React, { useEffect, useState } from 'react'
import Master from '../Includes/Master'
import Paper from '@mui/material/Paper';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Adddriver_admin, DriverlistdataList } from '../../Redux/Actions/Authenticaiton';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';





function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}

const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export default function Drivermanagement() {
    // const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const [id, setid] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(DriverlistdataList())
    }, [])
    let driver_listData = useSelector((state) => state.userSigninReducer.driver_list)

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            city: "",
            phone_number: "",
            vehicle_brand: "",
            model: "",
            build_year: "",
            plate_number: "",
            password_data: ""


        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('First Name is required'),
            last_name: Yup.string().required('Last Name is required'),
            // email:Yup.string().email().required('Email is required') ,
            city: Yup.string().required('City is required'),
            vehicle_brand: Yup.string().required('Brand is required'),
            phone_number: Yup.string().required('Phone is required'),
            build_year: Yup.string().required('Year is required'),
            model: Yup.string().required('Model is required'),
            plate_number: Yup.string().required('Plate Number is required'),
            password_data: Yup.string().required('Password is required')

        }),
        onSubmit: (values) => {
            values.id = id;

            dispatch(Adddriver_admin(values)).then(function () {
                dispatch(DriverlistdataList())
                setShow(false)
            })

        }
    })
    const edit_driver_detail = (driver_detail) => {

        setid(driver_detail?._id)
        formik.setFieldValue('first_name', driver_detail?.first_name)
        formik.setFieldValue('last_name', driver_detail?.last_name)
        formik.setFieldValue('email', driver_detail?.email)
        formik.setFieldValue('city', driver_detail?.city)
        formik.setFieldValue('phone_number', driver_detail?.phone_number)
        formik.setFieldValue('vehicle_brand', driver_detail?.vehicle?.vehicle_brand)
        formik.setFieldValue('build_year', driver_detail?.vehicle?.build_year)
        formik.setFieldValue('model', driver_detail?.vehicle?.model)
        formik.setFieldValue('password_data', driver_detail?.vehicle?.password_data)

        formik.setFieldValue('plate_number', driver_detail?.vehicle?.plate_number)
        setShow(true)
    }


    const { t } = useTranslation();


    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: t('Home.2'),
            width: '50%',
        },
        {
            id: 'calories',
            numeric: true,
            disablePadding: false,
            label: t('Home.29'),
            width: '20%',
        },
        {
            id: 'fat',
            numeric: true,
            disablePadding: false,
            label: t('Home.17'),
            width: '20%',
        },
        {
            id: 'carbs',
            numeric: true,
            disablePadding: false,
            label: t('Home.30'),
            width: '20%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.31'),
            width: '10%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.32'),
            width: '10%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.33'),
            width: '5%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.password_data'),
            width: '5%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.Edit Driver'),
            width: '5%',
        },
        {
            id: 'protein',
            numeric: true,
            disablePadding: false,
            label: t('Home.Block'),
            width: '5%',
        },
    ];

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (

            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ width: headCell.width }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(driver_listData, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    return (

        <div >
            <div className="card shadow mb-4">
                <div className="card-header py-3 button-driver">
                    <div >
                        <h2 style={{ color: "#DAA520" }}>{t('Home.11')}</h2>
                    </div>
                    <div><Button href="#" style={{ backgroundColor: "#DAA520", color: "white" }} onClick={handleShow} >{t('Home.Create driver')}</Button></div>
                </div>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {row.first_name + " "+ row.last_name}
                                            </TableCell>
                                            <TableCell align="right">{row.city}</TableCell>
                                            <TableCell align="right">{row.phone_number}</TableCell>
                                            <TableCell align="right">{row?.vehicle?.vehicle_brand}</TableCell>
                                            <TableCell align="right">{row?.vehicle?.model}</TableCell>
                                            <TableCell align="right">{row?.vehicle?.build_year}</TableCell>
                                            <TableCell align="right">{row?.vehicle?.plate_number}</TableCell>
                                            <TableCell align="right">{row?.password_data}</TableCell>
                                            <TableCell align="right"><Button style={{ color: "white", backgroundColor: "#DAA520" }} onClick={()=>edit_driver_detail(row)}>{t('Home.Edit')}</Button></TableCell>
                                            <TableCell align="right"><Button style={{ color: "white", backgroundColor: "red" }}>{t('Home.Block')}</Button></TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                        <TableCell colSpan={8} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </Paper>

            </div>
            <Modal show={show} onHide={handleClose}>
                <br/><br/>

                <Modal.Header closeButton>
                    <Modal.Title>{t('Home.13')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <form className="user" onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <input type="text" className="form-control form-control-user" id="firstname"
                                    placeholder="First name" name="first_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.first_name}

                                />
                                {formik.errors.first_name && <p style={{ color: "red" }}>{formik.errors.first_name} </p>}
                            </div>
                            <div className="col-sm-6">
                                <input type="tel" className="form-control form-control-user" id="lastname"
                                    placeholder="Last Name"
                                    name="last_name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.last_name}

                                />
                                {formik.errors.last_name && <p style={{ color: "red" }}>{formik.errors.last_name} </p>}
                            </div>
                        </div>
                        <div className="form-group row">
                            {/* <div className="col-sm-6 mb-3 mb-sm-0">
                                <input type="email" className="form-control form-control-user"   id="email"
                                    placeholder={t('Home.28')}
                                    name='email'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email} 
                                    />
                                    {formik.errors.email && <p style={{color:"red"}}>{formik.errors.email} </p>}    
                            </div> */}
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className="form-control form-control-user"
                                    id="city"
                                    placeholder={t('Home.29')}

                                    name='city'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                />
                                {formik.errors.city && <p style={{ color: "red" }}>{formik.errors.city} </p>}
                            </div>

                            <div className="col-sm-6">
                                <input
                                    type="tel"
                                    className="form-control form-control-user"
                                    id="phoneNumber"
                                    placeholder={t('Home.17')}
                                    name='phone_number'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone_number}


                                />
                                {formik.errors.phone_number && <p style={{ color: "red" }}>{formik.errors.phone_number} </p>}
                            </div>
                        </div>
                        <h1 className="h5 text-gray-900 mb-4">{t('Home.Vehicle Details')}:</h1>
                        <div className="col-sm-12 mb-3 p-0">
                            <input
                                type="tel"
                                className="form-control form-control-user"
                                id="vehiclebrand"
                                placeholder={t('Home.30')}
                                name='vehicle_brand'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.vehicle_brand}


                            />
                            {formik.errors.vehicle_brand && <p style={{ color: "red" }}>{formik.errors.vehicle_brand} </p>}
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <input
                                    type="text"
                                    className="form-control form-control-user"
                                    id="model"
                                    placeholder={t('Home.31')}
                                    name='model'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.model}



                                />
                                {formik.errors.model && <p style={{ color: "red" }}>{formik.errors.model} </p>}
                            </div>
                            <div className="col-sm-6 ">
                                <input type="text" className="form-control form-control-user" id="Year"
                                    placeholder={t('Home.32')}

                                    name='build_year'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.build_year}

                                />
                                {formik.errors.build_year && <p style={{ color: "red" }}>{formik.errors.build_year} </p>}
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3 mt-2 p-0">
                            <input
                                type="text"
                                className="form-control form-control-user"
                                id="Plate Number"
                                placeholder={t('Home.33')}

                                name='plate_number'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.plate_number}

                            />
                            {formik.errors.plate_number && <p style={{ color: "red" }}>{formik.errors.plate_number} </p>}
                        </div>

                        <div className="col-sm-12 mb-3 mt-2 p-0">
                            <input
                                type="text"
                                className="form-control form-control-user"

                                placeholder={t('Home.password_data')}

                                name='password_data'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password_data}

                            />
                            {formik.errors.password_data && <p style={{ color: "red" }}>{formik.errors.password_data} </p>}
                        </div>

                        <button className="btt btn-primary">{t('Home.13')} </button>
                    </form>


                </Modal.Body>

            </Modal>

        </div>
    )
}








