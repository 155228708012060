import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom'
import { Companyhistory, Drivertriphistory } from '../../Redux/Actions/Authenticaiton';
import Master from '../Includes/Master'
import { CSVLink, CSVDownload } from "react-csv";
import { t } from 'i18next';
export default function Company_reports() {
    const dispatch=useDispatch();
var {id}=useParams();
  useEffect(()=>{
    dispatch(Companyhistory(id))
  },[id])
  let driver_listData=useSelector((state)=>state.userSigninReducer.company_history_list)

 var csvData=[];
 csvData.push(['Customer name','Mobile number','Customer Pickup Loction','Customer Drop Loction','Ride type','Ride date','Ride Time','Ride status'])
  driver_listData.forEach(element => {

    csvData.push(  [element?.trip_id?.username, element?.trip_id?.phone_number,
       element?.trip_id?.pick_up_location_name,
      
       element?.trip_id?.drop_up_location_name,
       element?.trip_id?.ride_type,
       element?.trip_id?.date,
       element?.trip_id?.time,
      ])
  });
  

  const datacheck=(data)=>{
    var data_print=[];
for(var i=0;i<parseInt(data);i++){
    data_print.push(<span className="fa fa-star checked"></span>)
    
}
return data_print
}
  return (
    <Master>
    <div className="card shadow mb-4">
                          <div className="card-header py-3 button-driver">
                              <div >
                                  {/* <h6 className="m-0 font-weight-bold text-primary">{t('Home.11')}</h6> */}
                              </div>
                              <div>
                              {/* <NavLink to={`/drivercommision/${id}`} className="main-btn-cl"> {t('Home.Driver Commision')}</NavLink>; */}
                              <CSVLink data={csvData} className="main-btn-cl"> {t('Home.Dwonload CSV File')}</CSVLink>
                              
                              </div>
                          </div>
                          <div className="card-body">
                              <div className="table-responsive">
                                  <table className="table table-bordered triptable" id="dataTable" width="1750px" cellspacing="0">
                                      <thead>
                                          <tr>
                                          <th>{t('Home.16')}</th>
                                            <th>{t('Home.17')}</th>
                                            <th>{t('Home.18')}</th>
                                            <th>{t('Home.19')}</th>
                                            <th>{t('Home.20')} </th>
                                            <th>{t('Home.20')} </th>
                                          
                                                <th> {t('Home.Ride Fare')}</th>
                                              
                                                <th> {t('Home.21')}</th>
                                                <th> {t('Home.22')}</th>
                                                <th> {t('Home.23')}</th>
                                                <th>{t('Home.Rating by driver')}</th>
                                        </tr>
                                      </thead>
                                    
                                      <tbody>
                                        {driver_listData.map((driver_obj,i)=>{
                                       

                                     
                                          return(<tr key={i}>


                                                    <td>{driver_obj?.trip_id?.username}</td>
                                                      <td>{driver_obj?.trip_id?.phone_number}</td>
                                                      <td>{driver_obj?.trip_id?.pick_up_location_name}</td>
                                                      <td>{driver_obj?.trip_id?.drop_up_location_name}</td>
                                                      <td>{driver_obj?.trip_id?.ride_type}</td>
                                                      <td>{driver_obj.trip_id?.ride_time_type==1?"instant":"scheduled"}</td>
                                                      <td>{driver_obj.price} R/s</td>
                                                      <td>{driver_obj?.trip_id?.date}</td>
                                                      <td>{driver_obj?.trip_id?.time}</td>
                                                   

                                                    
                                                          
                                                          
                                                          <td>Completed</td>
                                              <td className="starrating">
                                            
                                                {
                                                  datacheck(driver_obj.trip_id?.raiting)
                                                }
                          
                          
                         
                     
                                                                      
                                              </td>
                                          </tr>
                                         )
                                        
                                        })}
                                          
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
 </Master>
  )
}
