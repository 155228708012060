import { useFormik } from 'formik';
import { t } from 'i18next'
import React from 'react'
import { useState } from 'react';
import Button from '@mui/material/Button';
import { Modal } from 'react-bootstrap';
import Master from '../Includes/Master'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addcomission, GetCommision } from '../../Redux/Actions/Authenticaiton';
import { useEffect } from 'react';
export default function Admincommision() {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const [id, setid] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const formik = useFormik({
        initialValues: {
            commision: "",




        },
        validationSchema: Yup.object({
            commision: Yup.string().required('Commision is required'),

        }),
        onSubmit: (values) => {
            values.id = id;

            dispatch(addcomission(values))
            dispatch(GetCommision())
            setShow(false)

        }
    })
    const edit_driver_detail = (driver_detail) => {

        setid(driver_detail?._id)

        formik.setFieldValue('commision', driver_detail?.commision)
        setShow(true)
    }


    useEffect(() => {
        dispatch(GetCommision())
    }, [])
    let commisionList = useSelector((state) => state.userSigninReducer.commision)

    return (
        <div >
            <br /><br /><br />
            <div className="card shadow mb-4">
                <div className="card-header py-3 button-driver">
                    <div >
                        <h2 style={{ color: "#DAA520" }}>{t('Home.Admin commision')}</h2>
                    </div>
                    <div>
                    <Button href="#" style={{ backgroundColor: "#DAA520", color: "white" }}   onClick={handleShow} >{t('Home.Add commision')}</Button>
                    
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered triptable" id="dataTable" width="1750px" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>{t('Home.Commision')} %</th>

                                    <th> {t('Home.Edit')}</th>

                                </tr>
                            </thead>

                            <tbody>
                                {
                                    commisionList.map(function (commision_obj, i) {
                                        return (
                                            <tr key={i}>
                                                <td>{commision_obj.commision}</td>
                                                <td ><button className="main-btn-cl" onClick={() => edit_driver_detail(commision_obj)}>{t('Home.Edit')}</button></td>

                                            </tr>
                                        )
                                    })
                                }






                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <br /><br />
                <Modal.Header closeButton>
                    <Modal.Title>{t('Home.Add commision')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <form className="user" onSubmit={formik.handleSubmit}>
                        <div className="form-group row">
                            <div className="col-sm-12 mb-3 mb-sm-0">
                                <input type="text" className="form-control form-control-user" id="firstname"
                                    placeholder={t('Home.Commision') + ' %'} name="commision"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.commision}

                                />
                                {formik.errors.commision && <p style={{ color: "red" }}>{formik.errors.commision} </p>}
                            </div>

                        </div>


                        <button className="btt btn-primary">{t('Home.Add commision')} </button>
                    </form>


                </Modal.Body>

            </Modal>
        </div>
    )
}
