
import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import usa from "../../images/usa.Avif"
import iseral from "../../images/iseral.avif"

import img from "../../images/img.png"
import img2 from "../../images/img2.png"
import { Link } from 'react-router-dom'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import TripListIcon from '@mui/icons-material/FormatListBulleted';
import ScheduleTripIcon from '@mui/icons-material/Event';
import AddDriverIcon from '@mui/icons-material/PersonAdd';
import TripHistoryIcon from '@mui/icons-material/History';
import RateListIcon from '@mui/icons-material/AttachMoney';
import ViewMapIcon from '@mui/icons-material/Map';
import AdminCommissionIcon from '@mui/icons-material/MonetizationOn';
import BusinessIcon from '@mui/icons-material/Business';
import { Button } from 'react-bootstrap';
import { Avatar } from '@mui/material';
import ViewMap from './ViewMap';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// interface AppBarProps extends MuiAppBarProps {
//   open?: boolean;
// }

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Viewmapmaster() {
  const { t } = useTranslation();
  const [langs, Setlang]=useState('ko')

  function handleClick(lang) {
      Setlang(lang)
    i18next.changeLanguage(lang)
  }


  useEffect(()=>{
      i18next.changeLanguage(langs)
  },"")
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href="/"   
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{background:"#DAA520"}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Avatar sx={{ width: 56, height: 56 }}  alt="Travis Howard" src={img}  />

          <Typography variant="h6" style={{marginLeft:"13px",color:"white"}} component="div">
          TAXI TLV
          </Typography>
          <div style={{ marginLeft: "1000px",display:"flex" }}>
          <Avatar src={iseral} href="#"  sx={{ width: 56, height: 36,cursor: 'pointer' }} onClick={() => handleClick('ko')} variant="square"  >
            
            </Avatar>
            
            <Avatar src={usa} href="#"  sx={{ width: 56, height: 36,cursor: 'pointer',marginLeft:"6px" }} onClick={() => handleClick('en')} variant="square" >
              
            </Avatar>
          </div>
          <ul  style={{ marginTop: 0,marginBottom:0 }}>
            <div className="topbar-divider d-none d-sm-block"></div>
            <li className="nav-item dropdown no-arrow">
              <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="mr-2 d-none d-lg-inline text-gray-600 small">Admin</span>
                <img className="img-profile rounded-circle"
                  src={require("../Assets/img/undraw_profile.svg").default} sx={{width: 56, height: 56 }} />
              </a>
              <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="userDropdown">
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  {t('Home.Logout')}
                </a>
              </div>
            </li>
          </ul>

        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
  {[
    { text: `${t('Home.8')}`, icon: <AddIcon />, link: '/add_trip' },
    { text: `${t('Home.9')}`, icon: <TripListIcon />, link: '/trip-list' },
    { text: `${t('Home.10')}`, icon: <ScheduleTripIcon />, link: '/scheduledtrip' },
    { text: `${t('Home.13')}`, icon: <AddDriverIcon />, link: '/add-driver' },
    { text: `${t('Home.12')}`, icon: <TripHistoryIcon />, link: '/trip-history' },
    { text: `${t('Home.Company card')}`, icon: <BusinessIcon />, link: '/companycard' },
    { text: `${t('Home.Rate list')}`, icon: <RateListIcon />, link: '/ratelist' },
    { text: `${t('Home.view_map')}`, icon: <ViewMapIcon />, link: '/mapshow' },
    { text: `${t('Home.Admin commision')}`, icon: <AdminCommissionIcon />, link: '/admincommision' },
    
  ].map(({ text, icon, link }, index) => {
    if (text === t('Home.7')) {
      return null;
    }
    return (
      <ListItem
        key={text}
        disablePadding
        sx={{ display: 'block' }}
        component={Link}
        to={link}
        style={{ textDecoration: 'none' }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
            title={text}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={text}
            sx={{ opacity: open ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    );
  })}
</List>
        <Divider />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <ViewMap/>
      </Box>
    </Box>
  );
}






