import React from 'react'
import Master from '../Includes/Master'
import { t } from 'i18next';
export default function DriverCommision() {
  return (
    <Master>
    <div className="card shadow mb-4">
                          <div className="card-header py-3 button-driver">
                              <div >
                                  <h6 className="m-0 font-weight-bold text-primary">{t('Home.11')}</h6>
                              </div>
                              <div>
                            
                              </div>
                          </div>
                          <div className="card-body">
                              <div className="table-responsive">
                                  <table className="table table-bordered triptable" id="dataTable" width="1750px" cellspacing="0">
                                      <thead>
                                          <tr>
                                          <th>{t('Home.16')}</th>
                                            <th>{t('Home.17')}</th>
                                            <th>{t('Home.18')}</th>
                                            <th>{t('Home.19')}</th>
                                            <th>{t('Home.20')} </th>
                                            <th>{t('Home.20')} </th>
                                          
                                                <th> {t('Home.Ride Fare')}</th>
                                              
                                                <th> {t('Home.21')}</th>
                                                <th> {t('Home.22')}</th>
                                                <th> {t('Home.23')}</th>
                                                <th>{t('Home.Rating by driver')}</th>
                                        </tr>
                                      </thead>
                                    
                                      <tbody>
                                       
                                          
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
 </Master>
  )
}
